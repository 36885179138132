import React from 'react';
import card from '../../../../assets/images/atmLike.svg';
import others from '../../../../assets/images/otherpayment.svg';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const index = ({ onButtonClick, back }) => {
  return (
    <div className="p-6">
      <div className="flex gap-6 items-center pb-16">
        <BsChevronLeft onClick={back} />
        <h3 className="font-FjallaOne text-2xl text-[#C25D41]">
          Payment Option
        </h3>
      </div>
      <div className="px-10">
        <div
          className="border-b-2 flex gap-[18rem] py-2 px-1 items-center"
          onClick={onButtonClick}
        >
          <div className="flex gap-10 items-center">
            <img src={card} alt="Atm like card" />
            <p className="font-Lora text-lg opacity-80">Card</p>
          </div>
          <div className="rounded-full p-2 bg-[#EAF3F0]">
            <BsChevronRight />
          </div>
        </div>
        <div className="border-b-2 flex gap-[17.1rem] py-2 px-1 items-center">
          <div className="flex gap-11 items-center">
            <img src={others} alt="Atm like card" />
            <p className="font-Lora text-lg opacity-80">Others</p>
          </div>
          <div className="rounded-full p-2 bg-[#EAF3F0]">
            <BsChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
