import React from 'react';
import mastercard from '../../../../assets/images/masterCardLogo.svg';
import { BsChevronLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Button from '../../../../components/shared/Button';

const index = ({ onButtonClick }) => {
  return (
    <div className="p-6">
      <div className="flex gap-6 items-center pb-16">
        <BsChevronLeft onClick={onButtonClick} className="cursor-pointer" />
        <h3 className="font-FjallaOne text-2xl text-[#C25D41]">Card Details</h3>
      </div>
      <div className="px-10">
        <div className="flex gap-4 items-center pb-3">
          <input type="Radio" className="accent-[#C25D41] w-5 h-5" />
          <div>
            <div className="flex gap-2 items-center">
              <img alt="MasterCard brand logo" src={mastercard} />
              <p>Master Card **** 8963</p>
            </div>
            <p className="font-light text-sm opacity-80 pt-2">
              Expiry April 30
            </p>
          </div>
          <div className="shadow-md rounded-xl px-3 py-2 bg-white">
            <p className="text-[#707070] text-xs">Change</p>
            <p className="text-[#707070] text-xs">Delete</p>
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <input type="Radio" className="accent-[#C25D41] w-5 h-5" />
          <div>
            <div className="flex gap-2 items-center">
              <img alt="MasterCard brand logo" src={mastercard} />
              <p>Master Card **** 8963</p>
            </div>
            <p className="font-light text-sm opacity-80 pt-2">
              Expiry April 30
            </p>
          </div>
          <div className="shadow-md rounded-xl px-3 py-2 bg-white">
            <p className="text-[#707070] text-xs">Change</p>
            <p className="text-[#707070] text-xs">Delete</p>
          </div>
        </div>
        <Link className="flex justify-center pt-52">
          <Button title="Add Card" variant="primary" />
        </Link>
      </div>
    </div>
  );
};

export default index;
