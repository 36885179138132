import React from 'react';
import mark from '../../../../assets/images/whiteTickWithOrangeBg.svg';
import Button from '../../../../components/shared/Button';

const index = ({
  heading,
  price,
  subheading,
  benefitOne,
  benefitTwo,
  benefitThree,
  benefitFour,
  benefitFive,
  benefitSix,
  benefitSeven,
  benefitEight,
}) => {
  return (
    <div className="bg-[#EAF3F0] hover:bg-white p-3 hover:rounded-md hover:p-8">
      <div>
        <h2 className="font-FjallaOne text-4xl pb-12 text-[#352B26]">
          {heading}
        </h2>
        <h4 className="font-FjallaOne text-2xl pb-6">{price}</h4>
        <p className="w-[11rem] font-Lora text-xl pb-3">{subheading}</p>
        <div className="py-5 flex flex-col gap-3">
          <div className="flex gap-2 items-center">
            <img src={mark}  alt="zulfah"/>
            <p>{benefitOne}</p>
          </div>
          <div className="flex gap-2 items-center">
            <img src={mark}  alt="zulfah"/>
            <p>{benefitTwo}</p>
          </div>
          <div className="flex gap-2 items-center">
            <img src={mark}  alt="zulfah" />
            <p>{benefitThree}</p>
          </div>
          <div className="flex gap-2 items-center">
            <img src={mark}  alt="zulfah" />
            <p>{benefitFour}</p>
          </div>
          <div className="flex gap-2 items-center">
            <img src={mark}  alt="zulfah" />
            <p>{benefitFive}</p>
          </div>
          <div className="flex gap-2 items-center">
            <img src={mark}  alt="zulfah" />
            <p>{benefitSix}</p>
          </div>
          <div className="flex gap-2 items-center">
            <img src={mark}  alt="zulfah" />
            <p>{benefitSeven}</p>
          </div>
          <div className="flex gap-2 items-center">
            <img src={mark}  alt="zulfah" />
            <p>{benefitEight}</p>
          </div>
        </div>
        <div className="lg:text-center">
          <Button title="Select plan" variant="primary" />
        </div>
      </div>
    </div>
  );
};

export default index;
