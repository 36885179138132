import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { IoClose } from 'react-icons/io5';

const Drawer = ({ isOpen, onRequestClose, children }) => (
  <Transition show={isOpen} as={Fragment}>
    <Dialog open={isOpen} onClose={onRequestClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full h-screen items-end justify-end p-4 sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-80 translate-x-2"
            enterTo="opacity-100 translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-80 translate-x-2"
          >
            <div className="relative flex flex-col h-screen bg-white transform transition-all sm:my-8 sm:w-full sm:max-w-xl">
              <div className="flex flex-row justify-end items-center p-4 sm:p-10">
                <button
                  type="button"
                  className="flex flex-row justify-center items-center bg-white text-[#000] hover:opacity-90 transition-opacity duration-300 appearance-none focus:outline-none"
                  onClick={onRequestClose}
                >
                  <div>
                    <IoClose size={48} />
                  </div>
                </button>
              </div>
              <div className="flex flex-col grow overflow-hidden">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default Drawer;
