import React from 'react';
import SideNav from './components/SideNav';
import Header from './components/Header';
import RightNav from './components/RightNav'

const index = ({ children }) => {
  return (
    <div className="flex bg-[#EAF3F0]">
      <div className="w-[20%]">
        <SideNav />
      </div>
      <div className="w-[80%]">
        <Header />
        {children}
      </div>
      {/* <div className="w-[20%]">
        <RightNav />
      </div> */}

    </div>
  );
};

export default index;
