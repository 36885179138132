import React from 'react';
import mark from '../../assets/images/whiteTickWithOrangeBg.svg';
import Button from '../../components/shared/Button';
import { Link } from 'react-router-dom';

const SubscriptionCard = ({
  heading,
  price,
  popular,
  benefitOne,
  benefitTwo,
  benefitThree,
  benefitFour,
  benefitFive,
  benefitSix,
  benefitSeven,
  benefitEight,
  onClick,
}) => {
  return (
    <div className="" onClick={onClick}>
      <p className="bg-gradient-to-r from-[#C25D41BD] to-[#0EE0EDBD] px-6 rounded-3xl w-[6rem] font-FjallaOne text-[8.11px] text-[#352B26]">
        {popular}
      </p>
      <div className="flex lg:justify-between md:justify-center md:gap-16 sm:gap-36 xs:gap-32">
        <h2 className="font-FjallaOne text-2xl sm:text-xl xs:text-xl text-[#352B26]">
          {heading}
        </h2>
        <h4 className="font-FjallaOne text-2xl sm:text-xl xs:text-xl text-[#352B26] opacity-80">
          {price}
        </h4>
      </div>
      <div className="py-8 items-center flex flex-col gap-3">
        <div className="flex gap-2 items-center">
          <img src={mark} alt="" />
          <p>{benefitOne}</p>
        </div>
        <div className="flex gap-2 items-center">
          <img src={mark} alt="" />
          <p>{benefitTwo}</p>
        </div>
        <div className="flex gap-2 items-center">
          <img src={mark} alt="" />
          <p>{benefitThree}</p>
        </div>
        <div className="flex gap-2 items-center">
          <img src={mark} alt="" />
          <p>{benefitFour}</p>
        </div>
        <div className="flex gap-2 items-center">
          <img src={mark} alt="" />
          <p>{benefitFive}</p>
        </div>
        <div className="flex gap-2 items-center">
          <img src={mark} alt="" />
          <p>{benefitSix}</p>
        </div>
        <div className="flex gap-2 items-center">
          <img src={mark} alt="" />
          <p>{benefitSeven}</p>
        </div>
        <div className="flex gap-2 items-center">
          <img src={mark} alt="" />
          <p>{benefitEight}</p>
        </div>
      </div>
      <div className="text-center">
        <p className="font-Lora text-sm pb-1">Terms and agreement</p>
        <Link to="">
          <Button title="Select plan" variant="primary" />
        </Link>
        <p className="text-[9px] font-Lora pt-1">Lorem ipsum dolor sit amet</p>
      </div>
    </div>
  );
};

export default SubscriptionCard;
