import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/shared/Button';
import { BsChevronLeft } from 'react-icons/bs';

const index = ({ back,onButtonClick }) => {
  return (
    <div className="p-6">
      <div className="flex gap-6 items-center pb-20">
        <BsChevronLeft onClick={back} />
        <h3 className="font-FjallaOne text-2xl text-[#C25D41]">Add A Card</h3>
      </div>
      <div className="flex flex-col px-9 gap-32">
        <div className="flex flex-col gap-6">
          <p className="font-Lora w-[415.22px] text-xl text-[#595757]">
            Your card details are instantly encrypted and stored securely
          </p>
          <form>
            <div>
              <div className="flex flex-col">
                <label className="font-Lora text-sm">Card Number</label>
                <input
                  placeholder="16digits number in front of your card"
                  type="text"
                  className="py-3 px-4 bg-[#F8F8F8] outline-none rounded-md"
                />
              </div>
              <div className="grid grid-cols-2 gap-x-5 pt-4">
                <div className="flex flex-col">
                  <label className="font-Lora text-sm">Card Expiry Date</label>
                  <input
                    placeholder="08/24"
                    type="text"
                    className="py-3 px-4 bg-[#F8F8F8] outline-none rounded-md"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-Lora text-sm">Card CVV</label>
                  <input
                    placeholder="866"
                    type="text"
                    className="py-3 px-4 bg-[#F8F8F8] outline-none rounded-md"
                  />
                  <p>
                    <small className="">
                      *Check the 3-digits behind your card
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Link className="text-center">
          <Button
            title="Pay with Card"
            variant="primary"
            onClick={onButtonClick}
          />
        </Link>
      </div>
    </div>
  );
};

export default index;
