import React from 'react';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import { BiLogoFacebook } from 'react-icons/bi';
import logo from '../../assets/images/logo.svg';

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="py-4 px-[5rem] md:px-[2rem] sm:flex sm:flex-col sm:gap-14 sm:py-8 sm:px-0 sm:items-center xs:flex xs:flex-col xs:gap-12 xs:py-6 xs:px-0 xs:items-center flex justify-between items-center">
        <div className="w-32 h-14">
          <img src={logo} alt="Gasleet Logo" />
        </div>
        {/* <h4 className="text-2xl font-FjallaOne font-normal">GASLEET</h4> */}
       <div>
        <p className="text-[#2D2E2E] text-sm">
           <a href="https://drive.google.com/file/d/1CriFR4fsBTMdNJ-w4ql6RcM7EF2aBevM/view?usp=sharing" target='_blank'>Privacy Policy </a> 
        </p>
          <p className="text-[#2D2E2E] text-sm">
          © 2025 Gasleet. All rights reserved
        </p>
        </div>
       
        <div className="flex gap-3 items-center">
          <a href="">
            <AiOutlineInstagram size={24} className="text-[#2D2E2E]" />
          </a>
          <a href="">
            <BiLogoFacebook size={24} className="text-[#2D2E2E]" />
          </a>
          <a href="">
            <AiOutlineTwitter size={24} className="text-[#2D2E2E]" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
