import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js'; 
import { Bar } from 'react-chartjs-2';

ChartJS.register( CategoryScale, LinearScale,BarElement,Title,Tooltip, Legend);
ChartJS.register(ArcElement);

const GasConsumption = () => {

    const UserData = [
        {
            id: 1,
            year: 2016,
            userGain: 80000,
            userLost: 8230,
        },
        {
            id: 2,
            year: 2017,
            userGain: 45677,
            userLost: 3450,
        },
        {
            id: 3,
            year: 2018,
            userGain: 78888,
            userLost: 5550,
        },
        {
            id: 4,
            year: 2019,
            userGain: 90000,
            userLost: 4555,
        },
        {
            id: 5,
            year: 2020,
            userGain: 4300,
            userLost: 2340,
        },
        {
            id: 6,
            year: 2021,
            userGain: 80000,
            userLost: 8230,
        },
        {
            id: 7,
            year: 2022,
            userGain: 45677,
            userLost: 3450,
        },
        {
            id: 8,
            year: 2023,
            userGain: 78888,
            userLost: 555,
        },
        {
            id: 9,
            year: 2024,
            userGain: 90000,
            userLost: 4555,
        },
        {
            id: 10,
            year: 2025,
            userGain: 4300,
            userLost: 2340,
        },
        {
            id: 11,
            year: 2026,
            userGain: 90000,
            userLost: 4555,
        },
        {
            id: 12,
            year: 2027,
            userGain: 4300,
            userLost: 2340,
        },
    ]

    return(
        <div className="bg-white sm:hidden md:block h-[300px] w-full md:w-[550px] p-4" 
        style={{height:"50vh",position:"relative", marginBottom:"1%", padding:"1%"}}
      >
          <Bar
          options = {{
              responsive: true,
              plugins: {
                  legend: {
                  position: 'top-right' 
                  },
                  title: {
                  display: true,
                  text: 'Gas Consumption insights',
                  resize: ""
                  },
              },
              scales: {
                  xAxes: [{
                      barThickness: 2,  // number (pixels) or 'flex'
                      maxBarThickness: 4 // number (pixels)
                  }]
              }
              }}

          data={{
              labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              datasets: [
                  {
                      label: 'Dataset 1',
                      data: UserData.map(o => o.userGain),
                      backgroundColor: '#5BCCF7',
                  }
              ],
              }}
          
          >
          
          </Bar>
      </div>
    )
}

export default GasConsumption;