import React, { useState } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import card from '../../assets/images/atmLike.svg';
import referral from '../../assets/images/referralLogo.svg';
import notification from '../../assets/images/bell.svg';
import bug from '../../assets/images/bug.svg';
import bam from '../../assets/images/bamidele.svg';
import DashboardBars from '../../components/shared/DashboardBars';
import whatsapp from '../../assets/images/whatsappIcon.svg';
import SettingCard from '../SettingCard';
import { Link } from 'react-router-dom';
import Drawer from '../../components/shared/Drawer/Drawer';
import Referrals from '../Referrals';

const Index = () => {
  const [showThis, setShowThis] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  function showSettingsCardComponent() {
    setShowThis(true);
    setIsDrawerOpen(true);
  }
  function showReferralComponent() {
    setShowThis(false);
    setIsDrawerOpen(true);
  }
  return (
    <>
      <DashboardBars>
        <div className="pt-10 px-10">
          <h1 className="text-[#C25D41] font-FjallaOne text-5xl pb-20">
            Settings
          </h1>
          <div className="flex gap-28">
            <div className="flex flex-col gap-24 w-1/2">
              <div>
                <div className="border-b-2 flex gap-[24rem] py-3 px-1 items-center">
                  <div className="flex gap-8 items-center">
                    <img src={card} alt="Atm like card" />
                    <p
                      onClick={showSettingsCardComponent}
                      className="font-Lora text-lg opacity-80 cursor-pointer"
                    >
                      Card
                    </p>
                  </div>
                  <BsChevronRight />
                </div>
                <div className="border-b-2 flex gap-[22rem] py-3 px-1 items-center">
                  <div className="flex gap-6 items-center">
                    <img
                      src={referral}
                      alt="A group of people in an icon like image to represent referrals"
                    />
                    <p
                      onClick={showReferralComponent}
                      className="font-Lora text-lg opacity-80 cursor-pointer"
                    >
                      Referrals
                    </p>
                  </div>
                  <BsChevronRight />
                </div>
                <div className="border-b-2 flex gap-[21rem] py-3 px-1 items-center">
                  <div className="flex gap-8 items-center">
                    <img
                      src={notification}
                      alt="A bell image to represent and notify user's of notificaton"
                    />
                    <Link
                      to="/settings-board/notification"
                      className="font-Lora text-lg opacity-80"
                    >
                      Notification
                    </Link>
                  </div>
                  <BsChevronRight />
                </div>
              </div>
              <div>
                <p className="font-Lora text-xl text-[#352B26] pb-10">
                  FEEDBACK
                </p>
                <div className="border-b-2 flex gap-[21rem] py-2 px-2 items-center">
                  <div className="flex gap-8 items-center">
                    <img src={bug} alt="Atm like card" />
                    <p className="font-Lora text-lg opacity-80">Report a bug</p>
                  </div>
                  <BsChevronRight />
                </div>
              </div>
            </div>
            <div className="w-1/2 relative bottom-12">
              <div className="flex flex-col items-center">
                <img src={bam} alt="Customer's image" className="h-14 w-14" />
                <p className="font-FjallaOne text-[#352B26] opacity-80 text-3xl">
                  Coker Oluwafemi
                </p>
              </div>
              <div className="border-2 text-center pt-2 pb-20">
                <p className="font-Lora text-[1.35rem] text-[#352B26] opacity-80">
                  Active Subscription : <span>Tier 1</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="fixed right-0 top-32">
          <img
            src={whatsapp}
            className="bg-white py-2 pr-10 pl-4 rounded-l-3xl"
            alt="Whatsapp icon to enable customer message on whatsapp messaging app."
          />
        </div>
      </DashboardBars>
      <Drawer
        isOpen={isDrawerOpen}
        onRequestClose={() => setIsDrawerOpen(false)}
      >
        {showThis ? <SettingCard /> : <Referrals />}
      </Drawer>
    </>
  );
};

export default Index;
