import axios from 'axios';
import store from '../store/store';
import appConfig from '../configs/app.config';
import { logout } from '../store/slices/account';

const Axios = axios.create({
  baseURL: appConfig.apiPrefix,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

Axios.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const { token: jwtToken } = state.account.data;

    if (jwtToken) {
      config.headers.Authorization = `Bearer ${jwtToken}`;
    }

    return config;
  },

  async (error) => {
    return await Promise.reject(error);
  },
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    if (Boolean(error.response) && error.response.status === 401) {
      store.dispatch(logout());
      sessionStorage.removeItem('persist:root');
    }

    return await Promise.reject(error);
  },
);

export default Axios;
