import React, { useState } from 'react';
// import Drawer from '../../components/shared/Drawer/Drawer';
import ReferralArea from './components/ReferralArea';
import ReferralCodeShare from './components/ReferralCodeShare';

const Index = () => {
  // const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [componentToShow, setComponentToShow] = useState(true);

  const componentChange = () => {
    setComponentToShow(false);
  };
  return (
    <>
      {componentToShow ? (
        <ReferralArea onButtonClick={componentChange} />
      ) : (
        <ReferralCodeShare />
      )}
    </>
  );
};

export default Index;
