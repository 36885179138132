import { useMutation } from "@tanstack/react-query";
import { login as loginApi, register as registerApi} from "../services/network/user";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export function useLogin() {
 const navigate = useNavigate();
 const { mutate: login, isLoading }  =  
  useMutation({
        mutationFn: ({email, password}) => loginApi(email, password),
        onSuccess: (data) => {
            toast.success(data.message || "Login success");
            navigate("/dashboard");
        },
        onError: (error) => {
            toast.error(error.response.data.message || "Email or password is incorrect");
            
        }
    });

return { login, isLoading };
}

// export function useRegister() {
//     const navigate = useNavigate();
//     const { mutate: register, isLoading }  =  
//     useMutation({
//             mutationFn: (data) => registerApi(data),
//             onSuccess: (data) => {
//                 toast.success(data.message || "Otp has been sent to the email");
//                 navigate("/login");
//             },
//             onError: (error) => {
//                 toast.error(error.response.data.message || "Something went wrong");
                
//             }
//         });
// }