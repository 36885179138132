// src/components/Accordion.js
import React, { useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { AiFillMinusCircle } from 'react-icons/ai';

const Index = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b-2 pt-1">
      <button
        onClick={toggleAccordion}
        className="w-full p-4 text-left flex lg:justify-between md:gap-10 items-center"
      >
        <h3 className="font-FjallaOne lg:text-3xl md:text-2xl md:font-bold sm:font-bold xs:font-bold text-xl text-[#352B26]">
          {title}
        </h3>
        {isOpen ? (
          <AiFillMinusCircle size={26} />
        ) : (
          <AiFillPlusCircle size={26} />
        )}{' '}
      </button>
      {isOpen && (
        <div className="p-5 font-Lora lg:text-xl md:text-xl text-lg text-[#352B26]">
          {content}
        </div>
      )}
    </div>
  );
};

export default Index;
