import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import TextInput from '../../../components/shared/TextInput';
import Button from '../../../components/shared/Button';
import blackCy from '../../../assets/images/gasContainerBlack.svg';
import Logo from '../../../assets/images/logo.svg';
import SideGasCylinder from '../../../components/shared/SideGasCylinder';
import logoLight from '../../../assets/images/logoWhite.svg';
import MessageIcon from '../../../assets/images/MessageIcon.svg';
import ReactCodeInput from 'react-code-input';
import './styles.css';
import OtpCountdownTimer from './OtpCountdownTimer';

const Index = () => {
  const reactCodeInputStyle = {
    appearance: 'none',
    height: '48px',
    width: '48px',
    fontFamily: 'GeneralSans',
    fontSize: '24px',
    fontWeight: 'normal',
    color: '#000000',
    border: '2px solid grey',
    MozAppearance: 'textfield',
    textAlign: 'center',
    margin: '0 0.5rem',
    borderRadius: '0.375rem',
    backgroundColor: '#F8F8F8',
  };
  const initialValues = {
    otp: '',
  };
  const [otp, setOtp] = useState('');

  const validationSchema = Yup.object().shape({
    otp: Yup.string().label('otp').email().required(),
  });

  const handleSubmitOtp = async (values) => {
    console.log(values);
  };
  const targetTime = Date.now() + 10 * 60 * 1000; // 10 minutes from now

  return (
    <>
      <SideGasCylinder />
      <div className="bg-[#EAF3F0] pb-6 max-[100%]">
        <section className="px-[7rem] pt-[65px] flex gap-12">
          <div className="w-1/2 py-48 flex flex-col gap-8">
            <img src={Logo} className="w-72" alt="Gasleet Logo" />
          </div>
          <div className="w-1/2 relative ">
            <div className="flex flex-col w-full p-3 md:p-0 md:w-2/3 lg:w-[500px] mt-10 md:mt-20 mb-16 md:mb-10">
              <div className="p-6 md:p-10 bg-white rounded-[15px]">
                <img src={MessageIcon} alt="Message " /> <br />
                <h1 className="text-[#C25D41] text-3xl font-FjallaOne font-semibold">
                  Confirm your <br /> email address
                </h1>
                <h4 className="text-black text-sm font-light">
                  Enter the 6 digit code we just sent to my@email.com
                </h4>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmitOtp}
                >
                  {({ handleSubmit, isValid }) => {
                    return (
                      <Form className="w-full mt-8" onSubmit={handleSubmit}>
                        <fieldset className="react-code-input">
                          <ReactCodeInput
                            name="otp"
                            type="text"
                            inputMode="numeric"
                            placeholder="*"
                            fields={6}
                            inputStyle={reactCodeInputStyle}
                            // onChange={(value) => setFieldValue('otp', value)}
                          />
                        </fieldset>
                        <span className="text-black text-xs font-light">
                          <OtpCountdownTimer targetTime={targetTime} />
                        </span>
                        <div className="flex flex-row mt-10">
                          <Button
                            type="submit"
                            title="Proceed"
                            variant="primary"
                            disabled={!isValid}
                          />
                        </div>
                        <br />
                        <br /> <br />
                        <br />
                        <br />
                        <br />
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Index;