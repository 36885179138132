import React, { useState } from 'react';
import Navbar from '../../components/shared/Navbar';
import SideGasCylinder from '../../components/shared/SideGasCylinder';
import { productCardRendering, showAccordion } from '../../utils';
import ProductCard from './components/ProductCard';
import Accordion from './components/Accordion';
import Footer from '../../components/shared/Footer';
const Index = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="bg-[#EAF3F0] relative pb-9">
      <SideGasCylinder />
      <Navbar />
      <div className="py-8 px-[7rem] md:px-6 sm:px-4 xs:px-3 md:pt-20 md:pb-10 sm:pt-20 sm:pb-8 xs:pt-16 xs:pb-7">
        <div className="sm:text-center xs:text-center">
          <h1 className="font-FjallaOne text-5xl md:text-4xl sm:text-3xl xs:text-2xl text-[#C25D41]">
            Lorem ipsum dolor sit amet consectetur. Nibh aliquam
          </h1>
          <p className="lg:w-[55rem] md: w-full font-Lora text-xl py-1 md:pt-3 border-b-2 sm:border-b-0 xs:border-b-0">
            Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
            sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
            consectetur. Tellus
          </p>
        </div>
        <div className="flex py-24 justify-center sm:flex-col sm:items-center sm:gap-12 sm:py-20 xs:flex-col xs:items-center xs:gap-9 xs:py-16 gap-20 md:grid md:grid-cols-2 md:justify-items-center md:gap-x-10 md:gap-y-4">
          {productCardRendering.map((item, i) => {
            return (
              <div key={i} className="">
                <ProductCard
                  heading={item.heading}
                  price={item.price}
                  subheading={item.subheading}
                  benefitOne={item.benefitOne}
                  benefitTwo={item.benefitTwo}
                  benefitThree={item.benefitThree}
                  benefitFour={item.benefitFour}
                  benefitFive={item.benefitFive}
                  benefitSix={item.benefitSix}
                  benefitSeven={item.benefitSeven}
                  benefitEight={item.benefitEight}
                />
              </div>
            );
          })}
        </div>
        <div>
          <h2 className="font-FjallaOne text-5xl text-[#352B26] sm:text-center xs:text-center py-4">
            FAQs
          </h2>
          <p className="font-Lora text-xl lg:w-[47rem] w-full sm:text-center xs:text-center pb-20">
            Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
            sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
            consectetur. Tellus
          </p>
          <div className="pb-12">
            {showAccordion.map((item, i) => {
              return (
                <div key={i} className="">
                  <Accordion title={item.title} content={item.content} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
