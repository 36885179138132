import React, { useState } from 'react';
import CardReg from './components/CardReg';
import CardSelection from './components/CardSelection';

const Index = () => {
  const [componentToShow, setComponentToShow] = useState(true);

  const componentChange = () => {
    setComponentToShow(false);
  };
  const componentBack = () => {
    setComponentToShow(true);
  };
  return (
    <>
      {componentToShow ? (
        <CardReg onButtonClick={componentChange} />
      ) : (
        <CardSelection onButtonClick={componentBack} />
      )}
    </>
  );
};

export default Index;
