import halfCy from '../../assets/images/gasContainerWhite.svg';
const SideGasCylinder = ({ props }) => {
  return (
    <div className={`${props} absolute left-0 bottom-8`}>
      <img src={halfCy} alt="Half Cylinder" />
    </div>
  );
};

export default SideGasCylinder;
