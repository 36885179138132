import React, { useState } from 'react';
import greenCy from '../../assets/images/gasContainerGreen.svg';
import SideGasCylinder from '../../components/shared/SideGasCylinder';
import Navbar from '../../components/shared/Navbar';
import Button from '../../components/shared/Button';
import LightButton from '../../components/shared/LightButton';
import { Link } from 'react-router-dom';
import atmLike from '../../assets/images/atmLike.svg';

const Index = () => {
  const [count, setCount] = useState(0);
  function increment() {
    return setCount(count + 1);
  }
  function decrement() {
    if (count > 0) {
      return setCount(count - 1);
    }
  }
  return (
    <div className="bg-[#EAF3F0] relative">
      <SideGasCylinder props="md:hidden sm:hidden xs:hidden" />
      <Navbar />
      <div className="lg:flex md:flex lg:items-center md:items-center px-28 py-12 md:py-20 lg:gap-16 md:gap-4 md:px-5 sm:px-2 xs:px-1">
        <div className="md:w-1/2">
          <h1 className="text-[#C25D41] font-FjallaOne text-5xl md:text-4xl sm:text-3xl xs:text-2xl sm:text-center xs:text-center">
            Instant Buy
          </h1>
          <p className="font-Lora text-sm sm:text-xs xs:text-xs opacity-60 sm:text-center xs:text-center">
            Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
            sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
            consectetur. Tellus
          </p>
          <form>
            <div className="flex flex-col">
              <div className="flex flex-col">
                <label>Name</label>
                <input
                  placeholder="John Doe"
                  type="text"
                  className="py-3 px-4 rounded bg-white outline-none"
                />
              </div>
              <div className="flex flex-col">
                <label>Phone No.</label>
                <input
                  placeholder="+234 8099998866"
                  type="text"
                  className="py-3 px-4 rounded bg-white outline-none"
                />
              </div>
              <div className="flex gap-8 md:gap-2 sm:gap-2 xs:gap-1">
                <div className="flex flex-col lg:w-[77%]">
                  <label className="z-10">Size</label>
                  <select className="py-3 px-4 md:px-2 sm:px-2 xs:px-2 rounded z-10 bg-white outline-none">
                    <option>Choose your Gas Container size.</option>
                    <option>3kg</option>
                    <option>5kg</option>
                    <option>6kg</option>
                    <option>12kg</option>
                    <option>25kg</option>
                  </select>
                </div>
                <div className="flex gap-4 md:gap-2 sm:gap-1 xs:gap-1 mt-6 items-center lg:w-[15%]">
                  <span
                    className="text-3xl px-3 md:px-2 xs:px-2 py-1 rounded bg-gray-200 cursor-default"
                    onClick={decrement}
                  >
                    -
                  </span>
                  <spam className="text-3xl">{count}</spam>
                  <span
                    className="text-3xl px-3 md:px-2 xs:px-2 rounded py-1 bg-gray-200 cursor-default"
                    onClick={increment}
                  >
                    +
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="">Address</label>
                <textarea
                  placeholder="1, Aduke Close, Maryland crescent, MaryLand, Ikeja, Lagos"
                  type="text"
                  rows="5"
                  className="py-3 px-4 rounded resize-none bg-white outline-none"
                />
              </div>
            </div>
            <p className="py-3">Pay with:</p>
            <div className="flex gap-3">
              <Link>
                <img
                  src={atmLike}
                  alt="An ATM resemblance identifying the option to pay with card."
                  className="absolute mt-3 ml-2 w-8 h-5"
                />
                <Button title="Card" variant="primary" />
              </Link>
              <LightButton
                title="Other Options"
                variant="primary"
                style="shadow-md bg-white text-black"
              />
            </div>
          </form>
        </div>
        <div className="bg-[#dfa08f] md:w-1/2 rounded-md p-4 sm:hidden xs:hidden">
          <div>
            <img
              src={greenCy}
              className="relative left-16 md:left-0"
              alt="A 12kg gas container showing Gasleet logo"
            />
          </div>
          <p className="font-Lora text-sm lg:w-[31rem] w-full opacity-60 text-center">
            With GasLeetApp, your loyalty pays off. Get rewarded for smart gas
            consumption. Earn loyalty points with each subscription and use them
            for discounts on future deliveries.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Index;
