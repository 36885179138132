import React from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import box from '../../../../assets/images/giftBox.svg';
import { Link } from 'react-router-dom';
import Button from '../../../../components/shared/Button';

const index = ({ onButtonClick }) => {
  return (
    <div className="p-4">
      <div className="flex gap-6 items-center pb-10">
        <BsChevronLeft />
        <h3 className="font-FjallaOne text-2xl text-[#C25D41]">Referrals</h3>
      </div>
      <div className="px-10 flex flex-col items-center">
        <p className="text-[#C25D41] w-[19.2rem] text-4xl font-FjallaOne text-center">
          Refer your friends and earn
        </p>
        <img src={box} alt="A gift box" className="w-[17rem] py-3 h-[17rem]" />
        <p className="text-[#00000099] w-[17rem] text-center">
          Lorem ipsum dolor sit amet consectetur. Volutpat id eu
        </p>
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Your referral code"
            className="text-[#352B2680] outline-none p-2 border-r border-r-[#EAF3F0] font-Lora"
          />
          <p className="text-[#352B2680] p-2 font-Lora text-xs">Copy</p>
        </div>
        <Link to="" className="flex justify-center pt-">
          <Button
            title="Share Code"
            variant="primary"
            onClick={onButtonClick}
          />
        </Link>
      </div>
    </div>
  );
};

export default index;
