import React from 'react';
import { userNotification } from '../../utils';
import NotificationArea from './components/NotificationArea';

const index = () => {
  return (
    <div className="bg-[#EAF3F0] h-screen p-6">
      <h1 className="text-[#C25D41] font-FjallaOne text-5xl pb-8 text-center">
        Notifications
      </h1>
      {userNotification.map((item, i) => {
        return (
          <div key={i} className="">
            <NotificationArea
              image={item.image}
              message={item.message}
              time={item.time}
            />
          </div>
        );
      })}
    </div>
  );
};

export default index;
