import { IoClose } from 'react-icons/io5';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const Modal = ({ isOpen, onRequestClose, children }) => (
  <Transition show={isOpen} as={Fragment}>
    <Dialog open={isOpen} onClose={onRequestClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative transform transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <button
                type="button"
                className="absolute -top-10 -right-3 flex flex-row justify-center items-center bg-white hover:opacity-90 transition-opacity duration-300 h-7 w-7 rounded-full text-[#0D2418] appearance-none focus:outline-none"
                onClick={onRequestClose}
              >
                <IoClose size={20} strokeWidth="10" />
              </button>
              <div className="bg-white p-4 sm:p-6 overflow-hidden rounded-lg">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default Modal;
