import bamidele from '../assets/images/bamidele.svg';
import zubairu from '../assets/images/zubairu.svg';

const reviewData = [
  {
    image: bamidele,
    rating: 5,
    name: 'Bamidele',
    review:
      'I love how Gasleet  has made buying gas online easy and how it has affected my household positively.',
  },
  {
    image: bamidele,
    rating: 5,
    name: 'Bamidele',
    review:
      'I love how Gasleet  has made buying gas online easy and how it has affected my household positively. Thank you Gasleet.',
  },
  {
    image: bamidele,
    rating: 5,
    name: 'Bamidele',
    review:
      'I love how Gasleet  has made buying gas online easy and how it has affected my household positively. Thank you Gasleet.',
  },
  {
    image: bamidele,
    rating: 5,
    name: 'Bamidele',
    review:
      'I love how Gasleet  has made buying gas online easy and how it has affected my household positively. Thank you Gasleet.',
  },
  {
    image: bamidele,
    rating: 5,
    name: 'Bamidele',
    review:
      'I love how Gasleet  has made buying gas online easy and how it has affected my household positively. Thank you Gasleet.',
  },
];

export { reviewData };

const productCardRendering = [
  {
    heading: 'Prompt',
    price: '#0.00',
    subheading: 'Lorem ipsum dolor amet consectetur.',
    benefitOne: 'Lorem ipsum dolor sitr',
    benefitTwo: 'Lorem ipsum dolor sitr',
    benefitThree: 'Lorem ipsum dolor sitr',
    benefitFour: 'Lorem ipsum dolor sitr',
    benefitFive: 'Lorem ipsum dolor sitr',
    benefitSix: 'Lorem ipsum dolor sitr',
    benefitSeven: 'Lorem ipsum dolor sitr',
    benefitEight: 'Lorem ipsum dolor sitr',
  },
  {
    heading: 'Tier 1',
    price: '#5,000.00',
    subheading: 'Lorem ipsum dolor amet consectetur.',
    benefitOne: 'Lorem ipsum dolor sitr',
    benefitTwo: 'Lorem ipsum dolor sitr',
    benefitThree: 'Lorem ipsum dolor sitr',
    benefitFour: 'Lorem ipsum dolor sitr',
    benefitFive: 'Lorem ipsum dolor sitr',
    benefitSix: 'Lorem ipsum dolor sitr',
    benefitSeven: 'Lorem ipsum dolor sitr',
    benefitEight: 'Lorem ipsum dolor sitr',
  },
  {
    heading: 'Tier 2',
    price: '#15,000.00',
    subheading: 'Lorem ipsum dolor amet consectetur.',
    benefitOne: 'Lorem ipsum dolor sitr',
    benefitTwo: 'Lorem ipsum dolor sitr',
    benefitThree: 'Lorem ipsum dolor sitr',
    benefitFour: 'Lorem ipsum dolor sitr',
    benefitFive: 'Lorem ipsum dolor sitr',
    benefitSix: 'Lorem ipsum dolor sitr',
    benefitSeven: 'Lorem ipsum dolor sitr',
    benefitEight: 'Lorem ipsum dolor sitr',
  },
  {
    heading: 'Tier 3',
    price: '#25,000.00',
    subheading: 'Lorem ipsum dolor amet consectetur.',
    benefitOne: 'Lorem ipsum dolor sitr',
    benefitTwo: 'Lorem ipsum dolor sitr',
    benefitThree: 'Lorem ipsum dolor sitr',
    benefitFour: 'Lorem ipsum dolor sitr',
    benefitFive: 'Lorem ipsum dolor sitr',
    benefitSix: 'Lorem ipsum dolor sitr',
    benefitSeven: 'Lorem ipsum dolor sitr',
    benefitEight: 'Lorem ipsum dolor sitr',
  },
];

export { productCardRendering };

const userNotification = [
  { image: zubairu, message: 'Just got a referral bonus.', time: 'Just now' },
  {
    image: zubairu,
    message: 'Did a premium subscription.',
    time: '59 minutes ago',
  },
  { image: zubairu, message: 'You got a point.', time: '12 hours ago' },
  {
    image: zubairu,
    message: 'You got a referral bonus.',
    time: 'Today 11:59am',
  },
];

export { userNotification };

const showAccordion = [
  {
    title: 'Lorem ipsum dolor sit amet consectetur. Nibh aliquam ',
    content:
      'Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna consectetur. Tellus ',
  },
  {
    title: 'Lorem ipsum dolor sit amet consectetur. Nibh aliquam ',
    content:
      'Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna consectetur. Tellus ',
  },
  {
    title: 'Lorem ipsum dolor sit amet consectetur. Nibh aliquam ',
    content:
      'Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna consectetur. Tellus ',
  },
  {
    title: 'Lorem ipsum dolor sit amet consectetur. Nibh aliquam ',
    content:
      'Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna consectetur. Tellus ',
  },
  {
    title: 'Lorem ipsum dolor sit amet consectetur. Nibh aliquam ',
    content:
      'Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna consectetur. Tellus ',
  },
];
export { showAccordion };
