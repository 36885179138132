import { Dialog, Transition } from '@headlessui/react'
import { Fragment} from 'react'
import logo from "../../assets/images/brandlogo.svg"
import gas from "../../assets/images/gas.svg"
import screws from "../../assets/images/screws.svg"

export default function DashboardModal( {show, close, initialFocus, NextFunction}) {

  return (
    <>

      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" 
                initialFocus={initialFocus}
                onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-gradient-to-b from-[#FFFFFF] to-[#a6ddf3] p-16 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className=" relative flex justify-center"
                  >
                    <img src={logo} alt="logo"></img>
                    <img src={screws} alt="alt" className='absolute -right-8 md:right-0 md:top-2' ></img>
                    
                  </Dialog.Title>
                    <p className='font-FjallaOne text-[25px] font-[400px] leading-[30px] text-[#352B26] my-6'>PROFILE YOUR GAS CONTAINER</p>
                    <div className="mt-2 flex justify-center">
                        <img src={gas} alt="gas"></img>
                    </div>
                    <p className="font-Lora text-[16px] font-[400px] leading-[19.2px] text-[#000000] my-6">
                    Lorem ipsum dolor sit amet consectetur. Volutpat id eu Lorem ipsum dolor sit amet consectetur. Volutpat id eu 
                    </p>
                  

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md bg-[#5BCCF7] px-20 py-3 font-FjallaOne text-[14px] font-[400px] leading-[14.6px] text-white"
                      onClick={() => NextFunction()}
                    >
                      EXPLORE MORE
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
