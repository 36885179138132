import React from 'react';

const index = ({ personImage, name, position }) => {
  //Vanilla CSS styling for adding backgroundImage to the cards
  const cardStyle = {
    background: `url(${personImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <div>
      {/*Individual images are directly passed as props to each card as well as name and position when
  rendered, as backgroundImage.*/}
      <div
        className="w-[23rem] relative h-[22.5rem] rounded-b-[4px]"
        style={cardStyle}
      >
        <div className="absolute bottom-2 left-2">
          <h4 className="font-FjallaOne text-2xl text-white">{name}</h4>
          <p className="font-Lora text-sm text-white">{position}</p>
        </div>
      </div>
    </div>
  );
};

export default index;