
import React, { useState } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store, { persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import Drawer from './components/shared/Drawer/Drawer';
import Modal from './components/shared/Modal/Modal';
import Profile from './views/Profile';
import Subscription from './views/Subscription';
import SettingsBoard from './views/SettingsBoard';
import SettingCard from './views/SettingCard';
import Fallback from './components/shared/Fallback';
import Referrals from './views/Referrals';
import ReferralCodeShare from './views/Referrals/components/ReferralCodeShare';
import Notification from './views/Notification';
import Faqs from './views/Faqs';
import LandingPage from './views/LandingPage';
import AboutUs from './views/AboutUs';
import ContactPage from './views/ContactPage';
import ProductPage from './views/ProductPage';
import Login from './views/Login';
import BuyGasPage from './views/BuyGasPage';
import CardPayment from './views/CardPayment';
import ForgetPassword from './views/ForgetPassword';
import Home from './views/Home';
import SignUp from './views/Signup';
import ConfirmEmail from './views/Signup/ConfirmEmail';
import SetPassword from './views/Signup/SetPassword';
import StartForgetPassword from './views/ForgetPassword/StartPasswordUpdate';
import CompleteForgetPassword from './views/ForgetPassword/CompletePasswordUpdate';
import Dashboard from './views/Dashboard'
import PrivacyPolicy from './views/Privacy';
import { Toaster } from 'react-hot-toast';

const App = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/drawer"
              element={
                <Drawer
                  isOpen={isDrawerOpen}
                  onRequestClose={() => setIsDrawerOpen(false)}
                />
              }
            />
              <Route path="/" element={<LandingPage />} />
              <Route path="/contact-us" element={<ContactPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              {/* <Route path="/dashboard" element={<Home />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/product" element={<ProductPage />} />
              <Route path="/buy-gas" element={<BuyGasPage />} />
              <Route path="/card-payment" element={<CardPayment />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/change-password/start" element={<StartForgetPassword />} />
              <Route path="/change-password/complete" element={<CompleteForgetPassword />} />
              <Route path="/create-account" element={<SignUp />} />
              <Route path="/create-account/confirm" element={<ConfirmEmail />} />
              <Route path="/create-account/set-password" element={<SetPassword />} />
              <Route path='/login' element={<Login />} />
              <Route path="/modal" element={<Modal />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/settings-board" element={<SettingsBoard />} />
              <Route path="/settings-board/card" element={<SettingCard />} />
              <Route path="/settings-board/referrals" element={<Referrals />} />
              <Route
                path="/settings-board/notification"
                element={<Notification />}
              />
               <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="*" element={<Fallback />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  );
};

export default App;






