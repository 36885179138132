import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js'; 
import { Bar, Doughnut } from 'react-chartjs-2';

ChartJS.register( CategoryScale, LinearScale,BarElement,Title,Tooltip, Legend);
ChartJS.register(ArcElement);

const GasPrice = () => {
    return(
        <div className=" h-[300px] w-full md:w-[550px] p-4">
        <p className="font-FjallaOne text-[17.39px] font-[400px] leading-[20.87px] text-[#352B26] pb-3">Current Gas price in market </p>
        <Doughnut
            data={{
            labels: ["Current", "Last week", "Last month"],
            datasets: [
                {
                data: ["5000"],
                backgroundColor: [ "#C25D41"],
                },
            ],
            }}
            width= {"250px"}
            options={{
            tooltips: {
                display: true,
                mode: "index",
                callbacks: {
                label: function (toolTipItem) {
                    return "₦" + toolTipItem.value;
                },
                },
            },
            plugins: {
                legend: {
                display: true,
                position: "bottom",
                boxWidth: "2px",
                padding: "10px",
                },
            },
            cutout: 90,
            //maintainAspectRatio : false,
            //aspectRatio : 2
            //responsive : true,
            }}
        ></Doughnut>
        <div>

        </div>
    </div> 
    )
}

export default GasPrice