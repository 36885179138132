import React from 'react';
import SideGasCylinder from '../../components/shared/SideGasCylinder';
import Navbar from '../../components/shared/Navbar';
import cooker from '../../../src/assets/images/gasCooker.svg';
import pot from '../../../src/assets/images/gasPot.svg';
import logo from '../../../src/assets/images/logoWhite.svg';
import { Link } from 'react-router-dom';
import Button from '../../components/shared/Button';
import Footer from '../../components/shared/Footer';
import AboutCard from './AboutCard';
import zub from '../../../src/assets/images/zubairu.svg';
import oj from '../../../src/assets/images/oje.svg';

const index = () => {
  return (
    <div className="relative bg-[#EAF3F0]">
      <SideGasCylinder />
      <Navbar />
      <div className="px-[7rem] py-16 md:px-6 sm:px-4 xs:px-3 md:pt-20 md:pb-10 sm:pt-20 sm:pb-8 xs:pt-16 xs:pb-7">
        <section className="px-[6rem] flex sm:flex-col gap-32 md:px-0 sm:px-0 xs:px-0 md:gap-2">
          <div className="w-1/2 sm:w-full xs:w-full">
            <h1 className="font-FjallaOne text-5xl sm:text-3xl xs:text-3xl sm:text-center xs:text-center text-[#C25D41] py-6">
              Do you want to be a part of our team{' '}
            </h1>
            <p className="font-Lora text-xl sm:text-center xs:text-center sm:text-base xs:text-base text-[#352B26] pb-8">
              Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
              sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
              consectetur. Tellus{' '}
            </p>
            <Link className="sm:flex sm:justify-center xs:flex xs:justify-center">
              <Button title="Join now" variant="primary" />
            </Link>
          </div>
          <div className="relative w-1/2 xs:hidden sm:w-full">
            <div className="relative bg-[#dfa08f] z-10 h-[18rem] w-[18rem] left-36 md:left-20 sm:left-20 bottom-3 rounded-full">
              <img
                src={pot}
                alt="A cooking pot showing GasLeet logo"
                className="absolute right-20 sm:right-16 bottom-3"
              />
              <img
                src={logo}
                alt="Gasleet white logo"
                className="absolute left-[3rem] -rotate-[15deg] bottom-16 h-12"
              />
            </div>
            <div className="">
              <img
                src={cooker}
                alt="A gas cooker with red flames."
                className="relative bottom-16 sm:bottom-10"
              />
            </div>
          </div>
        </section>
        <section className="pb-12">
          <p className="py-8 font-FjallaOne text-5xl sm:text-3xl xs:text-2xl text-center text-[#352B26]">
            Our story
          </p>
          <div className="flex gap-10 md:gap-6 sm:flex-col sm:gap-7 xs:flex-col xs:gap-6">
            <p className="text-xl sm:text-base xs:text-base font-Lora text-[#352B26] sm:text-center xs:text-center">
              Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
              sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
              consectetur. Tellus Lorem ipsum dolor sit amet consectetur. Nibh
              aliquam pulvinar nisl sed molestie arcu laoreet aliquam. Habitasse
              hendrerit massa magna consectetur. Tellus Lorem ipsum dolor sit
              amet consectetur. Nibh aliquam pulvinar nisl sed molestie arcu
              laoreet aliquam. Habitasse hendrerit massa magna consectetur.
              Tellus Lorem ipsum dolor sit amet consectetur. Nibh aliquam
              pulvinar nisl sed molestie arcu laoreet aliquam. Habitasse
              hendrerit massa magna consectetur. Tellus
            </p>
            <p className="text-xl font-Lora text-[#352B26] sm:text-base xs:text-base sm:text-center xs:text-center">
              Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
              sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
              consectetur. Tellus Lorem ipsum dolor sit amet consectetur. Nibh
              aliquam pulvinar nisl sed molestie arcu laoreet aliquam. Habitasse
              hendrerit massa magna consectetur. Tellus Lorem ipsum dolor sit
              amet consectetur. Nibh aliquam pulvinar nisl sed molestie arcu
              laoreet aliquam. Habitasse hendrerit massa magna consectetur.
              Tellus Lorem ipsum dolor sit amet consectetur. Nibh aliquam
              pulvinar nisl sed molestie arcu laoreet aliquam. Habitasse
              hendrerit massa magna consectetur. Tellus
            </p>
          </div>
        </section>
        <section>
          <p className="text-[#352B26] text-center text-5xl sm:text-3xl xs:text-2xl font-FjallaOne pt-8">
            Meet our Lead Members
          </p>
          <p className="font-Lora text-xl sm:text-base xs:text-base text-[#352B26] text-center py-6">
            Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
            sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
            consectetur. Tellus
          </p>
          <div className="grid grid-cols-3 md:grid-cols-2 justify-items-center gap-y-4 px-12 md:px-2 sm:grid-cols-1 xs:grid-cols-1 sm:px-1 xs:px-1 py-3">
            <AboutCard
              personImage={zub}
              name="Zubairu Oje"
              position="Frontend Lead"
            />
            <AboutCard
              personImage={oj}
              name="Sule Lajalaba"
              position="UI/UX Lead"
            />
            <AboutCard
              personImage={zub}
              name="Muntari Kolape"
              position="Back-end Lead"
            />
            <AboutCard personImage={oj} name="Gagarau BS" position="QA" />
            <AboutCard
              personImage={zub}
              name="Muttahiru Kashimawo"
              position="Frontend Lead"
            />
            <AboutCard
              personImage={oj}
              name="Zulu Gambari"
              position="Product Manager"
            />
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default index;
