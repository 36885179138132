import React from 'react';
import { RiHome2Fill } from 'react-icons/ri';
import { BsFillPersonFill } from 'react-icons/bs';
import { BsGear } from 'react-icons/bs';
import { GiPowerButton } from 'react-icons/gi';
import { MdSubscriptions } from 'react-icons/md';
import { AiFillQuestionCircle } from 'react-icons/ai';
import zulfahLogo from '../../../../../assets/images/zulfah.svg';
import bami from '../../../../../assets/images/bamidele.svg';
import { Link } from 'react-router-dom';

//#352B26
const SideNav = () => {
  return (
    <div className="flex flex-col gap-20 bg-[#EAF3F0] border-r-2">
      <div className="flex flex-col  items-center p-4 gap-16">
        <div className="flex sm:flex-col-reverse xs:flex-col-reverse gap-1 items-center">
          <img src={bami} alt="" className="w-11 h-11" />
          <div>
            <p className="font-Lora text-[9px] sm:text-[7px] xs:text-[6px] opacity-100 text-[#352B26]">
              Good morning
            </p>
            <p className="font-FjallaOne text-xs sm:text-[10px] xs:text-[9px] text-[#352B26]">
              Coker Oluwafemi
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex items-center gap-5 hover:border-l-2 hover:border-l-[#C25D41] rounded-md py-1 hover:bg-white px-8">
            <span>
              <RiHome2Fill
                className="text-gray-400 hover:text-[#C25D41]"
                size={25}
              />
            </span>
            <h5 className="font-FjallaOne text-base xs:hidden sm:hidden md:hidden">
              Dashboard
            </h5>
          </div>
          <div className="flex items-center gap-5 hover:border-l-2 hover:border-l-[#C25D41] rounded-md py-1 hover:bg-white px-8">
            <span>
              <MdSubscriptions
                className="text-gray-400 hover:text-[#C25D41]"
                size={25}
              />
            </span>
            <Link to="/subscription" className="font-FjallaOne text-base xs:hidden sm:hidden md:hidden">
              Subscription
            </Link>
          </div>
          <div className="flex items-center gap-5 hover:border-l-[#C25D41] hover:border-l-2 rounded-md py-1 hover:bg-white px-8">
            <span>
              <BsFillPersonFill
                className="text-gray-400 hover:text-[#C25D41]"
                size={25}
              />
            </span>
            <h5 className="font-FjallaOne text-base xs:hidden sm:hidden md:hidden">
              Profile
            </h5>
          </div>
          <div className="flex items-center gap-5 hover:border-l-[#C25D41] hover:border-l-2 rounded-md py-1 hover:bg-white px-8">
            <span>
              <BsGear
                className="text-gray-400 hover:text-[#C25D41]"
                size={25}
              />
            </span>
           
            <Link to="/settings-board" className="ont-FjallaOne text-base xs:hidden sm:hidden md:hidden">
              Settings
            </Link>
          </div>
          <div className="flex items-center gap-5 hover:border-l-[#C25D41] hover:border-l-2 rounded-md py-1 hover:bg-white px-8">
            <span>
              <AiFillQuestionCircle
                className="text-gray-400 hover:text-[#C25D41]"
                size={25}
              />
            </span>
           
            <Link to="/faqs" className="font-FjallaOne text-base xs:hidden sm:hidden md:hidden">
              FAQs
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex items-center gap-5 hover:border-l-[#C25D41] py-1 hover:border-l-2 rounded-md hover:bg-white px-8">
          <span>
            <GiPowerButton size={25} />
          </span>
          <h5 className="font-FjallaOne text-base sm:hidden xs:hidden md:hidden">
            Log-out
          </h5>
        </div>
        <div className="flex flex-col items-center gap-2 mt-32 p-3">
          <h5 className="font-FjallaOne text-base">From</h5>
          <div>
            <img src={zulfahLogo} alt="Zulfah" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
