import React from 'react';

const LightButton = ({ title, style }) => {
  return (
    <div>
      <button
        className={`${style} text-[#C25D41] inline-flex items-center justify-center py-2 px-10 rounded-md focus:outline-none`}
      >
        {title}
      </button>
    </div>
  );
};

export default LightButton;
