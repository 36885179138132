import { useState, useRef } from "react"
import cylinder from "../../assets/images/cylinder.svg"
import guage from "../../assets/images/guage.svg"
import buyinstant from "../../assets/images/buyinstant.svg"
import subscribe from "../../assets/images/subscribe.svg"
import deliver from "../../assets/images/deliver.svg"
import delivered from "../../assets/images/delivered.svg"
import pending from "../../assets/images/pending.svg"
import ProfileModal from "../DashboardModal/ProfileModal"
import DashboardModal from "../DashboardModal/DashboardModal"
import Navbar from "../../components/shared/Navbar"
import SideGasCylinder from "../../components/shared/SideGasCylinder"
import GasConsumption from "./components/GasConsumption"
import GasPrice from "./components/GasPrice"
import ThreeDashboardBars from "../../components/shared/DashboardBars"


const Dashboard = () => {

    const [startModalOpen, SetStartModalOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const [showInsight, setShowInsight] = useState(false);

    let [isOpen, setIsOpen] = useState(true)
    const cancelDashboardButtonRef = useRef(null)

    const NextOrPrevious = (variable) => {
        
        if (variable === 2) {
            setIsOpen(false)
            SetStartModalOpen(true)
        } 

    }

    const UserData = [
        {
            id: 1,
            year: 2016,
            userGain: 80000,
            userLost: 8230,
        },
        {
            id: 2,
            year: 2017,
            userGain: 45677,
            userLost: 3450,
        },
        {
            id: 3,
            year: 2018,
            userGain: 78888,
            userLost: 5550,
        },
        {
            id: 4,
            year: 2019,
            userGain: 90000,
            userLost: 4555,
        },
        {
            id: 5,
            year: 2020,
            userGain: 4300,
            userLost: 2340,
        },
        {
            id: 6,
            year: 2021,
            userGain: 80000,
            userLost: 8230,
        },
        {
            id: 7,
            year: 2022,
            userGain: 45677,
            userLost: 3450,
        },
        {
            id: 8,
            year: 2023,
            userGain: 78888,
            userLost: 555,
        },
        {
            id: 9,
            year: 2024,
            userGain: 90000,
            userLost: 4555,
        },
        {
            id: 10,
            year: 2025,
            userGain: 4300,
            userLost: 2340,
        },
        {
            id: 11,
            year: 2026,
            userGain: 90000,
            userLost: 4555,
        },
        {
            id: 12,
            year: 2027,
            userGain: 4300,
            userLost: 2340,
        },
    ]

    return (
        <ThreeDashboardBars>
         

            <DashboardModal NextFunction={()=> NextOrPrevious(2)} show={isOpen} close={setIsOpen} initialFocus={cancelDashboardButtonRef }  />
            <ProfileModal show={startModalOpen} close={SetStartModalOpen} initialFocus={cancelButtonRef}  />
            <div className="bg-[#EAF3F0] w-full">
            <SideGasCylinder props="md:hidden sm:hidden xs:hidden" />
             {/* <Navbar /> */}
                
              
                <hr className="text-[#000000] h-[3px] font-bold"/>

                <div className="p-8">

                    <div className="bg-white p-6 rounded-lg flex justify-between items-center mt-2">
                        <div>
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px] text-[#393634] mb-2">Referral Point Balance </p>
                            <p className="font-FjallaOne text-[49px] font-[400px] leading-[58.8px]">P59.00 </p>
                        </div>
                        <div className="flex">
                            <img src={cylinder} alt="cylinder" className="pt-10"></img>
                            <img src={guage} alt="guage" className="m"></img>
                        </div>
                    </div>

                    <div className="flex flex-col card w-full">
                    <div className="flex flex-row justify-between">
                    <h1 className="text-2xl my-4 font-medium card-header">Analytics</h1>
                 
                        <span className="text-xs text-gray-300 font-semi-bold mr-2">
                            Consumption
                        </span>
                        <div className="pretty p-switch p-fill ">
                            <input
                            type="checkbox"
                            name="analyticsView"
                            defaultChecked={showInsight}
                            onClick={(e) => setShowInsight(!showInsight)}
                            />
                            <div className="state">
                            <label />
                            </div>
                        </div>
                        <span className="text-xs text-gray-300 font-semi-bold ml-2">
                            Gas Prices
                        </span>
                   
                    </div>
                    </div>


                    {/* <div className="my-6 flex md:space-y-0 md:flex-col justify-between">
                        
                    </div> */}
                      <div className="flex-grow flex justify-center items-center">
                        {!showInsight ? <GasConsumption /> : <GasPrice />}
                    
                    </div>

                    <p className="pt-6 pb-4 font-FjallaOne text-[18px] font-[400px] leading-[19.2px]">Quick Action</p>
                    <div className="bg-white py-4 px-6 rounded-lg flex space-x-3 md:space-x-16">
                        <div className="flex space-x-2 items-center">
                            <img src={buyinstant} alt="cylinder"></img>
                            <p className="font-Lora text-[16px] font-[400px] leading-[19.2px]">Buy Instant</p>
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="17" height="16" fill="#1E1E1E"/> 
                                <g clipPath="url(#clip0_1_2)">
                                    <rect x="-383" y="-655" width="1440" height="1024" rx="8" fill="#EAF3F0"/> <rect x="-101" y="-22" width="874" height="58" rx="6.16168" fill="white"/>
                                    <path d="M4.5569 5.5827L7.7884 11.1797C8.2009 11.8947 8.8759 11.8947 9.2884 11.1797L12.5199 5.5822C12.9329 4.8672 12.5949 4.2832 11.7699 4.2832H5.3069C4.4819 4.2832 4.1439 4.8677 4.5569 5.5827Z" fill="#C25D41"/>
                                </g>
                                <defs> <clipPath id="clip0_1_2"> <rect x="-383" y="-655" width="1440" height="1024" rx="8" fill="white"/> </clipPath> </defs>
                            </svg>
                        </div>

                        <div className="flex space-x-2 items-center">
                            <img src={deliver} alt="deliveryicon"></img>
                            <p className="font-Lora text-[16px] font-[400px] leading-[19.2px]">Prompt Delivery</p>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.49949 6.625L8.87449 11L4.49949 15.375M11.4995 15.375H16.7495" stroke="#C25D41" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>

                        <div className="flex space-x-2 items-center" onClick={() => SetStartModalOpen(true)}>
                            <img src={subscribe} alt="subscriptionicon"></img>
                            <p className="font-Lora text-[16px] font-[400px] leading-[19.2px]">Subscription</p>
                        </div>

                    </div>

                    <div className="py-10">
                        <p className="font-Lora text-[20px] font-[400px] leading-[24px]"><span className="text-[#C25D41]">|</span> &nbsp;Today</p>
                        <div className="pt-6 pb-2 flex flex-row justify-between">
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px]"><sup className="font-FjallaOne text-[10px] font-[400px] leading-[15.8px]">OD</sup>02356</p>
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px] text-[#352B26]">21kg</p>
                            <img src={delivered} alt="delivered"></img>
                        </div>
                        <hr className=" w-3/4 border border-white mx-auto" />

                        <div className="pt-3 pb-2 flex flex-row justify-between">
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px]"><sup className="font-FjallaOne text-[10px] font-[400px] leading-[15.8px]">OD</sup>02356</p>
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px] text-[#352B26]">21kg</p>
                            <img src={pending} alt="delivered"></img>
                        </div>
                        <hr className=" w-3/4 border border-white mx-auto" />
                    </div>


                    <div className="pb-10">
                        <p className="font-Lora text-[20px] font-[400px] leading-[24px]"><span className="text-[#C25D41]">|</span> &nbsp;20-sep-2023</p>
                        <div className="pt-6 pb-2 flex flex-row justify-between">
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px]"><sup className="font-FjallaOne text-[10px] font-[400px] leading-[15.8px]">OD</sup>02356</p>
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px] text-[#352B26]">21kg</p>
                            <img src={delivered} alt="delivered"></img>
                        </div>
                        <hr className=" w-3/4 border border-white mx-auto" />

                        <div className="pt-3 pb-2 flex flex-row justify-between">
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px]"><sup className="font-FjallaOne text-[10px] font-[400px] leading-[15.8px]">OD</sup>02356</p>
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px] text-[#352B26]">21kg</p>
                            <img src={pending} alt="delivered"></img>
                        </div>
                        <hr className=" w-3/4 border border-white mx-auto" />

                        <div className="pt-3 pb-2 flex flex-row justify-between">
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px]"><sup className="font-FjallaOne text-[10px] font-[400px] leading-[15.8px]">OD</sup>02356</p>
                            <p className="font-Lora text-[18px] font-[400px] leading-[19.2px] text-[#352B26]">21kg</p>
                            <img src={pending} alt="delivered"></img>
                        </div>
                        <hr className=" w-3/4 border border-white mx-auto" />
                    </div>

                    
                </div>
                

            </div>
         </ThreeDashboardBars>
   
    )
}


export default Dashboard