import React, { Fragment } from 'react';

const TextInput = ({ field, form, ...props }) => {
  const { touched, errors } = form;
  const hasError = Boolean(touched[field.name] && errors[field.name]);

  return (
    <>
      <input
        name={field.name}
        value={field.value}
        className={`appearance-none w-100 mt-1 py-3.5 px-5 bg-[#F4F4F4] rounded-md text-sm text-gray-900 disabled:bg-gray-100 focus:outline-none transition ease-in-out duration-300`}
        onChange={field.onChange}
        onBlur={field.onBlur}
        {...props}
      />
      {hasError && (
        <span className="text-red-600 text-xs mt-1 animate-fade-in-down">
          {errors[field.name]}
        </span>
      )}
    </>
  );
};

export default TextInput;
