import React from 'react';
import { CiSearch } from 'react-icons/ci';
import { RxCounterClockwiseClock } from 'react-icons/rx';
import { IoIosNotificationsOutline } from 'react-icons/io';
import wallet from '../../../../../assets/images/walletLike.svg';

const Header = () => {
  return (
    <div className="bg-[#EAF3F0] lg:px-6 border-b-2">
      <div className="flex lg:gap-5 md:gap-4 sm:gap-3 xs:gap-2 items-center justify-end h-[5rem] p-3">
        <div className="flex items-center bg-white px-2 gap-1 rounded-[3rem] border">
          <CiSearch className="text-gray-700" />
          <input
            type="search"
            className="border-none rounded-[3rem] lg:w-[15rem] md:w-[13rem] sm:w-[10rem] xs:w-[9rem] h-[3rem] sm:h-[2rem] xs:h-[2rem] p-2 outline-none"
            placeholder="Search here..."
          />
        </div>
        <RxCounterClockwiseClock />
        <IoIosNotificationsOutline size={23} />
        <img src={wallet} />
      </div>
    </div>
  );
};

export default Header;
