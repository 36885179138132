// import React from 'react';
// import logo from '../../assets/images/logo.svg';
// import { Link } from 'react-router-dom';
// import Button from '../../components/shared/Button';
// import blackCy from '../../assets/images/gasContainerBlack.svg';
// import LightButton from '../../components/shared/LightButton';
// import logoLight from '../../assets/images/logoWhite.svg';
// import ashCy from '../../assets/images/gasContainerAsh.svg';
// import threeBlack from '../../assets/images/blackStone.svg';
// import halfMob from '../../assets/images/phone.svg';
// import playstorelogo from '../../assets/images/playStore.svg';
// import applestorelogo from '../../assets/images/appleStore.svg';
// import Card from './components/cards';
// import Navbar from '../../components/shared/Navbar';
// import Footer from '../../components/shared/Footer';
// import { reviewData } from '../../utils';
// import SideGasCylinder from '../../components/shared/SideGasCylinder';
// import pie from '../../assets/images/piechart.svg';
// import bar from '../../assets/images/barchart.svg';

// const LandingPage = () => {
//   return (
//     <div className="bg-[#EAF3F0] pb-6 h-screen">
//       {/* <Navbar />
//       <section className="px-[7rem] pt-32 flex gap-12 md:flex md:flex-col md:pt-20 md:px-6 sm:flex sm:flex-col sm:pt-16 sm:px-4 xs:pt-16 xs:px-3">
//         <div className="w-1/2 py-12 md:py-0 flex flex-col gap-8 md:w-full md:gap-6 sm:py-0 sm:gap-4 sm:pb-6 sm:w-full xs:py-0 xs:gap-2 xs:w-full">
//           <h1 className="font-FjallaOne font-normal text-5xl text-[#352B26] w-[21.5rem] md:w-full md:text-4xl md:text-center sm:w-full sm:text-2xl sm:text-center xs:w-full xs:text-2xl xs:text-center">
//             Earn
//             <span className="bg-gradient-to-r from-[#C25D41] to-[#00C2FF] inline-block text-transparent bg-clip-text">
//               Rewards
//             </span>
//             While You Cook
//           </h1>
//           <p className="text-[#352B26] text-xl font-Lora font-normal w-[31rem] md:w-full md:text-center sm:w-full sm:text-lg sm:text-center xs:w-full xs:text-lg xs:text-center">
//             With GasLeetApp, your loyalty pays off. Get rewarded for smart gas
//             consumption. Earn loyalty points with each subscription and use them
//             for discounts on future deliveries.
//           </p>
//           <div className="flex gap-9 md:justify-center">
//             <Link>
//               <LightButton
//                 title="Buy Instant"
//                 variant="primary"
//                 style={"border-2 border-[#C25D41] bg-[#EAF3F0]"}
//               />
//             </Link>
//             <Link>
//               <Button title="Explore more" variant="primary" />
//             </Link>
//           </div>
//         </div>
//         <div className="w-1/2 relative md:w-full sm:w-full xs:hidden">
//           <img
//             src={blackCy}
//             alt="Gas Container"
//             className="sm:w-[20rem] sm:h-[19rem]"
//           />
//           <img
//             src={logoLight}
//             alt="White Logo of Gasleet"
//             className="absolute bottom-[9rem] left-[13rem] rotate-[23deg] sm:bottom-[5rem] sm:left-[6.5rem] sm:w-20"
//           />
//           <img
//             src={pie}
//             className="absolute bottom-[23rem] left-[6rem] sm:w-32 sm:bottom-[11.8rem] sm:left-[.8rem]"
//             alt="zulfah"
//           />
//           <img
//             src={bar}
//             className="absolute bottom-[3rem] left-[26.5rem] sm:w-32 sm:bottom-[1.5rem] sm:left-[14rem]"
//             alt="zulfah"
//           />
//         </div>
//       </section>
//       <section className="relative flex overflow-x-hidden bg-gasleetPlayBg">
//         <div className="text-[#C25D41] text-xl font-Lora font-normal pt-24 pb-12 md:pb-8 sm:pb-6 xs:pb-4 animate-marquee whitespace-nowrap">
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//         </div>
//         <div className="text-[#C25D41] text-xl font-Lora font-normal pt-24 pb-12 md:pb-8 sm:pb-6 xs:pb-4 animate-marquee2 whitespace-nowrap top-0 absolute">
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//           <span className=" mx-3">GASLEET</span>
//         </div>
//       </section> */}
//       <section className="flex gap-6 px-[7rem] pt-24 pb-12 md:gap-4 md:px-6 md:pt-16 sm:flex-col sm:px-4 sm:pt-12 sm:gap-3 xs:flex-col xs:px-3 xs:pt-6 xs:gap-2">
//         <div className="flex flex-col gap-6 p-2 md:gap-4 sm:gap-3 xs:gap-2 sm:text-center xs:text-center">
//           <h4 className="font-normal font-FjallaOne text-3xl xs:text-xl xs:font-bold">
//             Lorem ipsum dolor sit amet
//           </h4>
//           <p className="text-xl font-Lora xs:text-lg">
//             Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
//             sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
//             consectetur. Tellus
//           </p>
//         </div>
//         <div className="flex flex-col gap-6 p-2 md:gap-4 sm:gap-3 xs:gap-2 sm:text-center xs:text-center">
//           <h4 className="font-normal font-FjallaOne text-3xl xs:text-xl xs:font-bold">
//             Lorem ipsum dolor sit amet
//           </h4>
//           <p className="text-xl font-Lora xs:text-lg">
//             Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
//             sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
//             consectetur. Tellus
//           </p>
//         </div>
//         <div className="flex flex-col gap-6 p-2 md:gap-4 sm:gap-3 xs:gap-2 sm:text-center xs:text-center">
//           <h4 className="font-normal font-FjallaOne text-3xl xs:text-xl xs:font-bold">
//             Lorem ipsum dolor sit amet
//           </h4>
//           <p className="text-xl font-Lora xs:text-lg z-10">
//             Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
//             sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
//             consectetur. Tellus
//           </p>
//         </div>
//       </section>
//       <section className="relative">
//         {/* <div className="flex justify-center gap-6 px-[10rem] md:gap-4 md:px-6 sm:flex-col sm:px-4 sm:gap-7 xs:flex-col xs:px-3 xs:gap-2">
//           <div className="flex flex-col gap-10 p-2 w-1/2 sm:w-full xs:w-full sm:text-center xs:text-center self-center">
//             <h4 className="font-normal font-FjallaOne text-3xl xs:text-2xl z-10">
//               Lorem ipsum dolor sit amet
//             </h4>
//             <p className="text-xl font-Lora w-[21.3rem] sm:w-full xs:w-full z-20">
//               Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
//               sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
//               consectetur. Tellus
//             </p>
//             <Link>
//               <Button title="Explore more" variant="primary" />
//             </Link>
//           </div>
//           <div className="bg-[#C25D41] p-10 w-[30rem] h-[30rem] md:w-[20rem] md:h-[20rem] rounded-full sm:w-[15rem] sm:h-[15rem] sm:left-20 relative xs:hidden">
//             <img
//               src={ashCy}
//               alt="6kg Cylinder"
//               className="absolute bottom-0 right-24 md:right-9 md:w-[20rem] md:h-[20rem] sm:w-[15rem] sm:h-[15rem] sm:right-8"
//             />
//             <img
//               src={threeBlack}
//               alt=""
//               className="-bottom-12 -left-2 absolute sm:-bottom-20"
//             />
//           </div>
//         </div> */}
//         <SideGasCylinder />
//       </section>
//       <section className="py-16 px-[7rem] md:px-6 sm:px-0 xs:px-0">
//         <div className="lg:bg-mobileBg lg:bg-cover lg:bg-no-repeat md:bg-[#C25D41] sm:bg-[#C25D41] xs:bg-[#C25D41] md:pl-2 md:justify-center rounded-r-2xl rounded-l-2xl sm:rounded-r-none sm:rounded-l-none xs:rounded-r-none xs:rounded-l-none flex sm:flex-col-reverse xs:flex-col-reverse sm:items-center gap-8 lg:justify-center ">
//           <div className="">
//             <img
//               className=""
//               src={halfMob}
//               alt="Mobile Phone displaying Gasleet on a mobile device"
//             />
//           </div>
//           <div className="flex self-center md:bg-white md:pt-12 md:pb-16 md:rounded-tl-[3rem] md:rounded-tr-3xl md:px-4 sm:bg-white sm:pt-10 sm:pb-8 sm:rounded-tl-[3rem] sm:rounded-tr-3xl xs:bg-white xs:pt-10 xs:pb-8 xs:rounded-tl-[3rem] xs:rounded-tr-3xl">
//             <div className="flex flex-col gap-2">
//               <p className="font-FjallaOne font-normal lg:w-[17rem] md:[15rem] sm:w-full xs:w-full sm:text-center xs:text-center text-4xl md:text-3xl sm:text-2xl xs:text-2xl text-[#C25D41]">
//                 Get the Gasleet Mobile App
//               </p>
//               <p className="font-Lora font-normal text-lg lg:w-[25rem] md:w-[22rem] sm:w-full xs:w-full sm:text-center xs:text-center md:text-base">
//                 Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar
//                 nisl sed molestie arcu laoreet aliquam. Habitasse hendrerit
//                 massa magna consectetur. Tellus
//               </p>
//               <div className="flex gap-4 sm:justify-center xs:justify-center">
//                 <Link>
//                   <img
//                     className="w-36 h-10"
//                     src={playstorelogo}
//                     alt="Google Playstore download Button"
//                   />
//                 </Link>
//                 <Link>
//                   <img
//                     className="w-36 h-10"
//                     src={applestorelogo}
//                     alt="AppleStore download Button"
//                   />
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <section className="py-20">
//         <h2 className="text-[#C25D41] font-FjallaOne px-[7rem] md:px-6 sm:px-4 xs:px-3 text-[3rem]">
//           Our reviews!
//         </h2>
//         <div className="relative flex overflow-x-hidden">
//           <div className="text-xl flex font-Lora font-normal pt-6 pb-12 animate-marquee whitespace-nowrap">
//             {reviewData.map((item, i) => {
//               return (
//                 <Card
//                   personImg={item.image}
//                   review={item.review}
//                   name={item.name}
//                   rating={item.rating}
//                 />
//               );
//             })}
//           </div>
//           <div className="text-xl flex font-Lora font-normal pt-6 pb-12  animate-marquee2 whitespace-nowrap top-0 absolute">
//             {reviewData.map((item, i) => {
//               return (
//                 <Card
//                   personImg={item.image}
//                   review={item.review}
//                   name={item.name}
//                   rating={item.rating}
//                 />
//               );
//             })}
//           </div>
//         </div>
//       </section> */}
//       {/* <Footer /> */}
//     </div>
//   );
// };

// export default LandingPage;




import React from 'react';
import logo from '../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
import Button from '../../components/shared/Button';
import blackCy from '../../assets/images/gasContainerBlack.svg';
import LightButton from '../../components/shared/LightButton';
import logoLight from '../../assets/images/logoWhite.svg';
import ashCy from '../../assets/images/gasContainerAsh.svg';
import threeBlack from '../../assets/images/blackStone.svg';
import halfMob from '../../assets/images/phone.svg';
import playstorelogo from '../../assets/images/playStore.svg';
import applestorelogo from '../../assets/images/appleStore.svg';
import Card from './components/cards';
import Navbar from '../../components/shared/Navbar';
import Footer from '../../components/shared/Footer';
import { reviewData } from '../../utils';
import SideGasCylinder from '../../components/shared/SideGasCylinder';
import pie from '../../assets/images/piechart.svg';
import bar from '../../assets/images/barchart.svg';

const LandingPage = () => {
  return (
    <div className="bg-[#EAF3F0] pb-6 h-screen">
      {/* <Navbar />
      <section className="px-[7rem] pt-32 flex gap-12 md:flex md:flex-col md:pt-20 md:px-6 sm:flex sm:flex-col sm:pt-16 sm:px-4 xs:pt-16 xs:px-3">
        <div className="w-1/2 py-12 md:py-0 flex flex-col gap-8 md:w-full md:gap-6 sm:py-0 sm:gap-4 sm:pb-6 sm:w-full xs:py-0 xs:gap-2 xs:w-full">
          <h1 className="font-FjallaOne font-normal text-5xl text-[#352B26] w-[21.5rem] md:w-full md:text-4xl md:text-center sm:w-full sm:text-2xl sm:text-center xs:w-full xs:text-2xl xs:text-center">
            Earn
            <span className="bg-gradient-to-r from-[#C25D41] to-[#00C2FF] inline-block text-transparent bg-clip-text">
              Rewards
            </span>
            While You Cook
          </h1>
          <p className="text-[#352B26] text-xl font-Lora font-normal w-[31rem] md:w-full md:text-center sm:w-full sm:text-lg sm:text-center xs:w-full xs:text-lg xs:text-center">
            With GasLeetApp, your loyalty pays off. Get rewarded for smart gas
            consumption. Earn loyalty points with each subscription and use them
            for discounts on future deliveries.
          </p>
          <div className="flex gap-9 md:justify-center">
            <Link>
              <LightButton
                title="Buy Instant"
                variant="primary"
                style={"border-2 border-[#C25D41] bg-[#EAF3F0]"}
              />
            </Link>
            <Link>
              <Button title="Explore more" variant="primary" />
            </Link>
          </div>
        </div>
        <div className="w-1/2 relative md:w-full sm:w-full xs:hidden">
          <img
            src={blackCy}
            alt="Gas Container"
            className="sm:w-[20rem] sm:h-[19rem]"
          />
          <img
            src={logoLight}
            alt="White Logo of Gasleet"
            className="absolute bottom-[9rem] left-[13rem] rotate-[23deg] sm:bottom-[5rem] sm:left-[6.5rem] sm:w-20"
          />
          <img
            src={pie}
            className="absolute bottom-[23rem] left-[6rem] sm:w-32 sm:bottom-[11.8rem] sm:left-[.8rem]"
            alt="zulfah"
          />
          <img
            src={bar}
            className="absolute bottom-[3rem] left-[26.5rem] sm:w-32 sm:bottom-[1.5rem] sm:left-[14rem]"
            alt="zulfah"
          />
        </div>
      </section>
      <section className="relative flex overflow-x-hidden bg-gasleetPlayBg">
        <div className="text-[#C25D41] text-xl font-Lora font-normal pt-24 pb-12 md:pb-8 sm:pb-6 xs:pb-4 animate-marquee whitespace-nowrap">
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
        </div>
        <div className="text-[#C25D41] text-xl font-Lora font-normal pt-24 pb-12 md:pb-8 sm:pb-6 xs:pb-4 animate-marquee2 whitespace-nowrap top-0 absolute">
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
          <span className=" mx-3">GASLEET</span>
        </div>
      </section> */}
      <section className="flex md:flex-col gap-6 px-[7rem] pt-24 pb-12 md:gap-4 md:px-6 md:pt-16 sm:flex-col sm:px-4 sm:pt-12 sm:gap-3 xs:flex-col xs:px-3 xs:pt-6 xs:gap-2">
        <div className="flex flex-col  gap-6 p-2 md:gap-4 sm:gap-3 xs:gap-2 sm:text-center xs:text-center">
          <h4 className="font-normal font-FjallaOne text-3xl xs:text-xl xs:font-bold">
              Never Run Out Again!
          </h4>
          <p className="text-xl font-Lora xs:text-lg">
            {/* Something sweet on gasleet for only tack here */}
           Never run out of gas again! With Gasleet, you can track your gas usage, refill your gas cylinder and relax while we deliver it to your doorstep.
          </p>
        </div>
        <div className="flex flex-col gap-6 p-2 md:gap-4 sm:gap-3 xs:gap-2 sm:text-center xs:text-center">
          <h4 className="font-normal font-FjallaOne text-3xl xs:text-xl xs:font-bold">
          Premium Safe Gas, Every Time
          </h4>
          <p className="text-xl font-Lora xs:text-lg">
            {/* Something sweet on gasleet for only tack here */}
            With Gasleet, you can be sure of the quality of gas you are getting. We ensure that your gas is safe and of the highest quality.
          </p>
        </div>
        <div className="flex flex-col gap-6 p-2 md:gap-4 sm:gap-3 xs:gap-2 sm:text-center xs:text-center">
          <h4 className="font-normal font-FjallaOne text-3xl xs:text-xl xs:font-bold">
          Your First Gas is On Us!
          </h4>
          <p className="text-xl font-Lora xs:text-lg z-10">
            {/* Something sweet on gasleet for only tack here */}
            Get your first gas delivery for free when you sign up on Gasleet. We are committed to making your first experience with us a memorable one.
          </p>
        </div>
      </section>
      <section className="relative">
        {/* <div className="flex justify-center gap-6 px-[10rem] md:gap-4 md:px-6 sm:flex-col sm:px-4 sm:gap-7 xs:flex-col xs:px-3 xs:gap-2">
          <div className="flex flex-col gap-10 p-2 w-1/2 sm:w-full xs:w-full sm:text-center xs:text-center self-center">
            <h4 className="font-normal font-FjallaOne text-3xl xs:text-2xl z-10">
              Lorem ipsum dolor sit amet
            </h4>
            <p className="text-xl font-Lora w-[21.3rem] sm:w-full xs:w-full z-20">
              Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
              sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
              consectetur. Tellus
            </p>
            <Link>
              <Button title="Explore more" variant="primary" />
            </Link>
          </div>
          <div className="bg-[#C25D41] p-10 w-[30rem] h-[30rem] md:w-[20rem] md:h-[20rem] rounded-full sm:w-[15rem] sm:h-[15rem] sm:left-20 relative xs:hidden">
            <img
              src={ashCy}
              alt="6kg Cylinder"
              className="absolute bottom-0 right-24 md:right-9 md:w-[20rem] md:h-[20rem] sm:w-[15rem] sm:h-[15rem] sm:right-8"
            />
            <img
              src={threeBlack}
              alt=""
              className="-bottom-12 -left-2 absolute sm:-bottom-20"
            />
          </div>
        </div> */}
        <SideGasCylinder />
      </section>
      <section className="py-16 px-[7rem] md:px-6 sm:px-0 xs:px-0">
        <div className="lg:bg-mobileBg lg:bg-cover lg:bg-no-repeat md:bg-[#C25D41] sm:bg-[#C25D41] xs:bg-[#C25D41] md:pl-2 md:justify-center rounded-r-2xl rounded-l-2xl sm:rounded-r-none sm:rounded-l-none xs:rounded-r-none xs:rounded-l-none flex sm:flex-col-reverse xs:flex-col-reverse sm:items-center gap-8 lg:justify-center ">
          <div className="">
            <img
              className=""
              src={halfMob}
              alt="Mobile Phone displaying Gasleet on a mobile device"
            />
          </div>
          <div className="flex self-center md:bg-white md:pt-12 md:pb-16 md:rounded-tl-[3rem] md:rounded-tr-3xl md:px-4 sm:bg-white sm:pt-10 sm:pb-8 sm:rounded-tl-[3rem] sm:rounded-tr-3xl xs:bg-white xs:pt-10 xs:pb-8 xs:rounded-tl-[3rem] xs:rounded-tr-3xl">
            <div className="flex flex-col gap-2">
              <p className="font-FjallaOne font-normal lg:w-[17rem] md:[15rem] sm:w-full xs:w-full sm:text-center xs:text-center text-4xl md:text-3xl sm:text-2xl xs:text-2xl text-[#C25D41]">
              Track. Refill. Relax.
              </p>
              <p className="font-Lora font-normal text-lg lg:w-[25rem] md:w-[22rem] sm:w-full xs:w-full sm:text-center xs:text-center md:text-base">
               {/* Something on Track , refill and Relax text */}
               GasLeet is a platform that allows you to track your gas usage, refill your gas cylinder and relax while we deliver it to your doorstep.
              </p><br />
              <div className="flex gap-4 sm:justify-center xs:justify-center justify-center" >
                <Link>
                  <img
                    className="w-36 h-10"
                    src={playstorelogo}
                    alt="Google Playstore download Button"
                  />
                </Link>
                <Link>
                  <img
                    className="w-36 h-10"
                    src={applestorelogo}
                    alt="AppleStore download Button"
                  />
                </Link>
              </div><br /><br />
              {/* buttin for a waitlist  */}
              <center>
              <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfLfBd9LVZrlt8IXvhLcrvbR_x8QzO8a3UGW47RXW97wULYZw/viewform">
                <Button title="Join Waitlist" variant="primary" className="bg-[#c25d41] w-[80%] md:w-[100%] py-5 px-2 rounded-lg text-[#fff]" />
              </Link>
              </center>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="py-20">
        <h2 className="text-[#C25D41] font-FjallaOne px-[7rem] md:px-6 sm:px-4 xs:px-3 text-[3rem]">
          Our reviews!
        </h2>
        <div className="relative flex overflow-x-hidden">
          <div className="text-xl flex font-Lora font-normal pt-6 pb-12 animate-marquee whitespace-nowrap">
            {reviewData.map((item, i) => {
              return (
                <Card
                  personImg={item.image}
                  review={item.review}
                  name={item.name}
                  rating={item.rating}
                />
              );
            })}
          </div>
          <div className="text-xl flex font-Lora font-normal pt-6 pb-12  animate-marquee2 whitespace-nowrap top-0 absolute">
            {reviewData.map((item, i) => {
              return (
                <Card
                  personImg={item.image}
                  review={item.review}
                  name={item.name}
                  rating={item.rating}
                />
              );
            })}
          </div>
        </div>
      </section> */}
      {/* <Footer /> */}
    </div>
  );
};

export default LandingPage;
