/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User } from '../../services/network';


const initialState = {
  data: {
    user: null,
    token: null,
  },
};

export const loginRequest = createAsyncThunk(
  'account/login',
   async (payload, thunkAPI) => {
    try {
      const response = await User.login(payload);
      const { jwtToken } = response.data.data;
      sessionStorage.setItem('jwtToken', jwtToken);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const signUpRequest = createAsyncThunk(
  'account/signUp',
  async (payload, thunkAPI) => {
    try {
      const response = await User.register(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    logout: (state) => {
      state.data = initialState.data;
    },
  },
  // extraReducers: {
  //   [loginRequest.fulfilled]: (state, action) => {
  //     state.data = action.payload.data;
  //   },
  //   [loginRequest.rejected]: (state) => {
  //     state.data = initialState.data;
  //   },
  // },
});

export const { logout } = accountSlice.actions;

export default accountSlice.reducer;
