import React from 'react';
import { Link } from 'react-router-dom';

const Fallback = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-[#EAF3F0]">
      <div className="text-center">
        <h1 className="text-4xl text-red-700 font-FjallaOne">Error 404</h1>
        <p className="text-2xl font-Lora py-2">Page/Resource not found.</p>
        <Link to="/" className="text-xl font-Lora text-blue-400">
          Go back to home
        </Link>
      </div>
    </div>
  );
};

export default Fallback;
