import Button from '../../components/shared/Button';
import blackCy from '../../assets/images/gasContainerBlack.svg';
import Logo from '../../assets/images/logo.svg';
import  SideGasCylinder from '../../components/shared/SideGasCylinder';
import logoLight from '../../assets/images/logoWhite.svg';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Footer from '../../components/shared/Footer';



const Index = () => {
  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().label('Email').email().required(),
    password: Yup.string().label('Password').required(),
  });

  const handleLogin = async (values) => {
    console.log(values);
  };

  return (
    <>
    <SideGasCylinder />
    <div className="bg-[#EAF3F0] h-full pb-6 max-[100%]">
      
      <section className="px-[7rem] pt-[65px] flex gap-12">
        <div className="w-1/2 py-48 flex flex-col gap-8">
           <img src={Logo} className=' w-72 '  alt='Gasleet Logo' />
        </div>
        <div className="w-1/2 relative ">
           <div className="flex flex-col w-full p-3 md:p-0 md:w-2/3 lg:w-[500px] mt-10 md:mt-20 mb-16 md:mb-10">
            <div className="p-6 md:p-10 bg-white rounded-[15px]">
              <h1 className="text-[#C25D41] text-3xl font-FjallaOne font-semibold">
                Update your <br />Password
              </h1><br />
                <h4 className="text-black text-sm font-light">
                    Need a password reset ?
                    Enter your email below.
              </h4>
              

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                {({ handleSubmit, isValid }) => {
                  return (
                    <Form className="w-full mt-8" onSubmit={handleSubmit}>

                      <div className="flex flex-col">
                        <label
                          htmlFor="email"
                          className="text-sm text-black"
                        >
                          Email Address
                        </label>
                        <Field
                        
                          type="email"
                          name="email"
                          id="email"
                          placeholder="user@email.com"
                        />
                      </div>

                    
                      <div className="flex flex-row mt-5 justify-end">
                        <span className="text-black text-xs font-light">
                        Already have an Account{" "}
                          <Link
                            to="/login"
                            className="text-[#000] font-semibold hover:text-[#000] transition ease-in-out duration-300"
                          >
                             Login
                          </Link>
                        </span>
                      </div>

                      <div className="flex flex-row mt-5">
                        <Button
                          type="submit"
                          title="Continue"
                          variant="primary"
                          disabled={!isValid}
                        />
                      </div>
                      <br /><br /> <br />
                      <br /><br /><br />
                    </Form>
                  );
                }}
              </Formik>

            
            </div>
          </div>
        </div>
      </section>

    </div>
    <Footer />
    </>
  );
};

export default Index;