import Countdown, { CountdownRenderProps } from 'react-countdown';

const OtpCountdownTimer = ({ targetTime }) => {
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <span>Code expired!</span>;
    } else {
      return (
        <>
          *Code expires in{' '}
          <span className="text-[#000] font-semibold">
            {`${minutes}:${seconds}sec`}
          </span>
        </>
      );
    }
  };

  return <Countdown date={targetTime} renderer={renderer} />;
};

export default OtpCountdownTimer;