import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react";
import { FaTimes } from "react-icons/fa";




const ProfileModal = ({show, close, initialFocus,}) => {
    return(
        <>
            <Transition.Root show={show} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 md:modalUp inset-0 overflow-y-auto"
                initialFocus={initialFocus}
                onClose={close}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-end sm:block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className="relative inline-block bg-[#FFFFFF] rounded-lg text-center overflow-hidden shadow-xl transform transition-all my-4 align-middle max-w-lg w-full">
                    
                    <div className="bg-white px-4 pt-4 pb-4 sm:p-4 sm:pb-4">

                        <div className="sm:px-2 sm:flex sm:flex-row absolute right-2">
                        <FaTimes
                            className="cursor-pointer text-[#352B26]"
                            size={30}
                            onClick={() => close(false)}
                        />
                        </div>

                        <div className=" sm:mt-0 text-left pt-4">
                        <Dialog.Title
                            as="h3"
                            className="px-4 flex flex-col"
                        >
                            <p className="text-[25px] font-[400px] leading-[30px] font-FjallaOne my-8 text-[#C25D41]">Gas Profile</p>
                        </Dialog.Title>
                            <div className='mt-2 md:mt-10'>
                                <div className="flex flex-col divide-y ">
                                    <p className="font-Lora text-[20px] font-[400px] leading-[24px] text-[#352B26] pb-4">Name : Coker Oluwafemi </p>
                                    <p className="font-Lora text-[20px] font-[400px] leading-[24px] text-[#352B26] pb-4 pt-6">Phone no : +2348063962344 </p>
                                    <p className="font-Lora text-[20px] font-[400px] leading-[24px] text-[#352B26] pb-4 pt-6">Gas type  : 12kg </p>
                                    <p className="font-Lora text-[20px] font-[400px] leading-[24px] text-[#352B26] pb-4 pt-6">Quantity   : 1  </p>
                                    <p className="font-Lora text-[20px] font-[400px] leading-[24px] text-[#352B26] pb-4 pt-6">Prompt : Monthly   </p>
                                    <p className="font-Lora text-[20px] font-[400px] leading-[24px] text-[#352B26] pb-4 pt-6">Prompt Due : 12-11-2023     09:30.00   </p>
                                    <p className="font-Lora text-[20px] font-[400px] leading-[24px] text-[#352B26] pb-4 pt-6">Address : No 3 Aduke Close Maryland Lagos </p>
                                    <p className="font-Lora text-[20px] font-[400px] leading-[24px] text-[#352B26] pb-4 pt-6">Active Subscription : Free   </p>

                                </div>
                            </div>
                            <hr/>
                                <div className="flex justify-center">
                                    <button className='bg-[#5BCCF7] rounded text-white px-24 py-2 text-[21.05px] font-[400px] leading-[25.26px] my-7 font-FjallaOne'>
                                        Profile Your Gas
                                    </button>
                                </div>
                            
                            
                        </div>

                    </div>
                    </div>
                </Transition.Child>
                </div>
            </Dialog>
            </Transition.Root>
        </>
    )
}


export default ProfileModal