import React, { useState } from 'react';
import ThreeDashboardBars from '../../components/shared/ThreeDashboardBars';
import { productCardRendering } from '../../utils';
import Drawer from '../../components/shared/Drawer/Drawer';
import SubscriptionCard from './SubscriptionCard';
import { BsChevronLeft } from 'react-icons/bs';
import mark from '../../assets/images/whiteTickWithOrangeBg.svg';
import Button from '../../components/shared/Button';
import PaymentOption from './components/PaymentOption';
// import CardDetails from './components/CardDetails';

const Index = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [currentDrawerContent, setCurrentDrawerContent] = useState(null);

  const handleCardClick = (cardData) => {
    setSelectedCard(cardData);
    setCurrentDrawerContent('cardValues');
    setIsDrawerOpen(true);
  };
  const handleSelectPlan = () => {
    setCurrentDrawerContent('paymentOption');
  };
  const handleGotoCardDetails = () => {
    setCurrentDrawerContent('AtmCardDetails');
  };
  const handleGoBackOne = () => {
    setCurrentDrawerContent('cardValues');
  };
  const handleGoBackTwo = () => {
    setCurrentDrawerContent('paymentOption');
  };
  return (
    <>
      <ThreeDashboardBars>
        <div>
          <div className="lg:px-12 md:px-4 px-2 py-3">
            <p className="font-FjallaOne text-[#C25D41] lg:text-4xl md:text-2xl text-xl">
              Lorem ipsum dolor sit amet consectetur. Nibh aliquam
            </p>
            <p className="font-Lora lg:w-[41rem] text-[#000000] md:text-sm">
              Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
              sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
              consectetur. Tellus
            </p>
          </div>
          <div className="lg:flex lg:flex-row flex lg:px-0 md:px-8 px-3 flex-col py-16 justify-center sm:gap-6 xs:gap-6 lg:gap-12 md:gap-6">
            {productCardRendering.map((item, i) => {
              return (
                <div key={i} className="">
                  <SubscriptionCard
                    heading={item.heading}
                    price={item.price}
                    subheading={item.subheading}
                    benefitOne={item.benefitOne}
                    benefitTwo={item.benefitTwo}
                    benefitThree={item.benefitThree}
                    benefitFour={item.benefitFour}
                    benefitFive={item.benefitFive}
                    benefitSix={item.benefitSix}
                    benefitSeven={item.benefitSeven}
                    benefitEight={item.benefitEight}
                    onClick={() => handleCardClick(item)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </ThreeDashboardBars>
      <Drawer
        isOpen={isDrawerOpen}
        onRequestClose={() => setIsDrawerOpen(false)}
      >
        {currentDrawerContent === 'cardValues' && selectedCard && (
          <div>
            <div className="p-4">
              <div className="flex gap-6 items-center pb-8">
                <BsChevronLeft />
                <h3 className="font-FjallaOne text-2xl text-[#C25D41]">
                  PRODUCT DETAILS
                </h3>
              </div>
              <div>
                <div className="flex justify-between">
                  <h2 className="font-FjallaOne text-4xl text-[#352B26]">
                    {selectedCard.heading}
                  </h2>
                  <h4 className="font-FjallaOne text-4xl text-[#352B26] opacity-80">
                    {selectedCard.price}
                  </h4>
                </div>
                <div className="py-8 items-center flex flex-col gap-3">
                  <div className="flex gap-2 items-center">
                    <img src={mark} alt="" />
                    <p>{selectedCard.benefitOne}</p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={mark} alt="" />
                    <p>{selectedCard.benefitTwo}</p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={mark} alt="" />
                    <p>{selectedCard.benefitThree}</p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={mark} alt="" />
                    <p>{selectedCard.benefitFour}</p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={mark} alt="" />
                    <p>{selectedCard.benefitFive}</p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={mark} alt="" />
                    <p>{selectedCard.benefitSix}</p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={mark} alt="" />
                    <p>{selectedCard.benefitSeven}</p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={mark} alt="" />
                    <p>{selectedCard.benefitEight}</p>
                  </div>
                </div>
                <div className="text-center">
                  <p className="font-Lora text-sm pb-1">Terms and agreement</p>
                  <Button
                    title="Select plan"
                    variant="primary"
                    onClick={handleSelectPlan}
                  />
                  <p className="text-[9px] font-Lora pt-1">
                    Lorem ipsum dolor sit amet
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentDrawerContent === 'paymentOption' && (
          <PaymentOption
            onButtonClick={handleGotoCardDetails}
            back={handleGoBackOne}
          />
        )}
        {/* {currentDrawerContent === 'AtmCardDetails' && (
          <CardDetails back={handleGoBackTwo} />
        )} */}
      </Drawer>
    </>
  );
};

export default Index;
