import React from 'react';

const index = ({ image, message, time }) => {
  return (
    <div className="flex gap-2">
      <img src={image} alt="" className="w-4 h-4" />
      <div>
        <p className="text-sm font-Lora">{message}</p>
        <p className="text-xs text-[#1C1C1C66] font-Lora">{time}</p>
      </div>
    </div>
  );
};

export default index;
