import React from 'react';
import Button from '../../components/shared/Button';
import SideGasCylinder from '../../components/shared/SideGasCylinder';
import blueCy from '../../assets/images/gasContainerBlue.svg';
import { Link } from 'react-router-dom';
import Footer from '../../components/shared/Footer';
import { BiSolidPhoneCall } from 'react-icons/bi';
import { ImLocation } from 'react-icons/im';
import { HiMail } from 'react-icons/hi';
import Navbar from '../../components/shared/Navbar';

const index = () => {
  return (
    <div className="bg-[#EAF3F0] pb-6">
      <Navbar />
      <section className="relative pt-24">
        <div className="px-[12rem] md:px-6 sm:px-4 xs:px-3 md:pt-28 md:pb-10 py-5 flex gap-5">
          <div className="w-1/2 sm:w-full xs:w-full">
            <h1 className="text-[#C25D41] font-FjallaOne text-5xl sm:text-3xl xs:text-2xl sm:text-center xs:text-center">
              Contact us
            </h1>
            <p className="font-Lora z-10 text-xl py-6 sm:text-base xs:text-base sm:text-center xs:text-center">
              Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
              sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
              consectetur. Tellus{' '}
            </p>
            <Link className="sm:flex sm:justify-center xs:flex xs:justify-center">
              <Button title="Contact us" variant="primary" />
            </Link>
          </div>
          <div className="bg-blueContainerBg bg-no-repeat relative top-40 md:top-48 w-1/2 sm:hidden xs:hidden">
            <img
              src={blueCy}
              alt="Blue Gas Cylinder showing Gasleet logo and a red knob."
              className="absolute bottom-32 left-24 md:bottom-40 md:left-20"
            />
          </div>
        </div>
      </section>
      <SideGasCylinder />
      <section className="pt-28 pb-16 md:pt-20 md:pb-10 sm:pt-14 sm:pb-8 xs:pt-16 xs:pb-7">
        <div className="px-[10rem] md:px-6 sm:px-4 xs:px-3 flex sm:flex-col xs:flex-col items-center gap-20">
          <div>
            <div className="flex gap-3">
              <BiSolidPhoneCall size={24} className="text-[#C25D41] z-10" />
              <div>
                <h4 className="font-FjallaOne text-2xl">Phone Number</h4>
                <p className="py-2 font-Lora text-xl">+234 8179276822</p>
              </div>
            </div>
            <div className="flex gap-3 py-3">
              <HiMail size={24} className="text-[#C25D41] z-10" />
              <div>
                <h4 className="font-FjallaOne text-2xl">Email Address</h4>
                <p className="py-2 font-Lora text-xl">support@gasleet.com</p>
              </div>
            </div>
            <div className="flex gap-3">
              <ImLocation size={24} className="text-[#C25D41] z-10" />
              <div>
                <h4 className="font-FjallaOne text-2xl">Location</h4>
                <p className="py-2 font-Lora text-xl">Ikeja, Lagos</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h2 className="font-FjallaOne text-4xl sm:text-2xl xs:text-xl sm:text-center xs:text-center">
                SEND MESSAGE
              </h2>
              <p className="font-Lora text-xl md:text-base sm:text-base xs:text-base sm:text-center xs:text-center py-4 z-auto">
                Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar
                nisl sed molestie arcu laoreet aliquam. Habita
              </p>
            </div>
            <form>
              <div>
                <div className="grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-x-4 gap-y-3">
                  <div className="flex flex-col">
                    <label className="font-Lora pb-2">First Name</label>
                    <input
                      className="bg-white rounded-md px-3 py-2 outline-none"
                      placeholder="John"
                      type="text"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="font-Lora pb-2">Last Name</label>
                    <input
                      className="bg-white rounded-md px-3 py-2 outline-none"
                      placeholder="Doe"
                      type="text"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="font-Lora pb-2">Phone</label>
                    <input
                      className="bg-white rounded-md px-3 py-2 outline-none"
                      placeholder="+234 8110682127"
                      type="text"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="font-Lora pb-2">Email</label>
                    <input
                      className="bg-white rounded-md px-3 py-2 outline-none"
                      placeholder="myexample@gmail.com"
                      type="email"
                    />
                  </div>
                </div>
                <div className="flex flex-col py-3">
                  <label className="font-Lora">Message</label>
                  <textarea
                    className="bg-white rounded-md resize-none px-3 py-2 outline-none"
                    placeholder="type your message here..."
                  ></textarea>
                </div>
                <Link className="flex justify-end">
                  <Button title="Send" variant="primary" />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default index;
