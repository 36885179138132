
//hlep me deign a privacy popcy page
import React from 'react';

import ThreeDashboardBars from '../../components/shared/ThreeDashboardBars';

const Index = () => {
    return (
        <>
        {/* <ThreeDashboardBars> */}
        <div className="flex flex-col items-center justify-center">
            <div className="w-[90%]">
            <div className="flex flex-col items-center justify-center">
                <br />
                <p className="font-FjallaOne text-[#C25D41] lg:text-4xl md:text-2xl text-xl">
                PRIVACY POLICY : GASLEET BY ZULFAH
                </p><br />
                <p className="font-Lora lg:w-[50rem] text-[#000000] md:text-sm">
                Gasleet understands that your privacy is important and that you care about how your
                personal data is used and shared onpne. We respect the privacy of everyone who visits our
                website and uses our onpne services. We are committed to respecting, securing, and
                protecting your personal data. We are also committed to being transparent about what we
                collect from you and how we use it. This privacy popcy covers information practices,
                including how we use, share, and secure the personal information you provide and your
                legal rights.<br /><br />
                <h3>Our contact details.</h3>
                Email: info@zulfahgroup.com<br />
                Phone: +2348093568767<br />
                Address: 7 Aduke Close Off Maryland Crescent, Maryland.

                <br /><br />

                <b>Your Rights.</b><br /><br />
                Under the Nigerian Data Protection Regulation (NDPR), you have several rights about your
                personal data. This popcy has been designed to uphold your rights where we act as a data
                processor: <br />
                <ul className='list-disc pl-5' > 
                    <li>Right of access – you have the right to request a copy of the information that we
                    hold about you.</li>
                   <li>Right of rectification – you have a right to correct data that we hold about you that is
                    inaccurate or incomplete.</li>
                    <li>Right to be forgotten – in certain circumstances you can ask for the data we hold
                    about to be erased from our record. </li>
                    <li>Right to restriction of processing right to restrict the processing.</li>
                    <li>Right of portabipty – where certain conditions apply to have a – you have the right to
                    have the data we hold about you transferred to another organization.</li>
                    <li>Right to object – you have the right to object to certain types of processing such as
                    direct marketing.</li>
                    <li>Right to object to automated processing, including profipng – you also have the right
                    not to be subject to automated processing or profipng.</li>
                    <li>Right to judicial review – if we refuse your request under rights of access, we will
                    provide you with a reason to why. You have the right to complain as detailed below.</li>
                </ul>
                <br /><br />
               
                <b>  Your right to complain.</b><br /><br />
              
                If you wish to make a complaint about how your personal data is being processed or how
                your complaint has been handled, you have the right to lodge a directly to our in-house data
                officer or with the supervisory authority. If we are unable to help, or you are not satisfied
                with our response, you also have the right to lodge a complaint with the Nigeria Data
                Protection Commission (NDPC). We would, however, appreciate the chance to deal with
                your concerns before you approach NDPC, so please contact us in the first instance.
                 <br /><br />
                Effective Date: 1st January 2025 <br /><br />

                Gasleet ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy
                explains how we collect, use, disclose, and safeguard your information when you visit our
                website www.gasleet.com (the "Website") or use our services. Please read this policy
                carefully. If you do not agree with the terms of this policy, please do not use our Website or
                services.<br /><br />

               <b> 1. INFORMATION WE COLLECT</b><br />
               <ul className='list-none pl-5' >
                <li>a. Information You Provide to Us: </li>
                    <ol className='list-disc pl-10'>
                        <li>
                            Personal Information: When you sign up for an account, place an order, or contact
                            us, we may collect information such as your name, email address, phone number,
                            delivery address, and payment information.
                        </li>
                        <li>
                             Feedback: Any messages, reviews, or inquiries you submit to us.
                        </li>
                    </ol>
                <li>
                    b. Information Collected Automatically: 
                    <ol className='list-disc pl-10'>
                        <li>
                            Log Information: We may collect information about your device, browser, and
                            IP address when you visit our Website.
                        </li>
                        <li>
                            Cookies: We use cookies to collect information about your browsing behavior.
                        </li>
                    </ol>
                </li>
                <li>
                    c. Information from Third Parties:
                    We may receive information from third-party service providers, such as payment processors
                    or delivery partners, as necessary to provide our services.
                </li>
                </ul>
                <br />
                <b> 2. HOW WE USE YOUR INFORMATION</b><br />
                We use the information we collect for the following purposes:
                <ul className='list-disc pl-5' >
                    <li>
                        To provide, maintain, and improve our Website and services.
                    </li>
                    <li>
                        To process your orders, payments, and deliveries.
                    </li>
                    <li>
                        To communicate with you, including responding to your inquiries and sending
                        updates about your orders.
                    </li>
                    <li>
                        To send promotional materials, offers, and updates, if you have opted in to receive
                        them.
                    </li>
                    <li>
                        To comply with legal obligations and enforce our terms and conditions.
                    </li>
                    <li>
                        To improve the functionality, performance, and security of our Website.
                    </li>
                </ul><br />
                
                <b>3. HOW WE SHARE YOUR INFORMATION</b> <br />
                We do not sell your personal information. However, we may share your information in the
                following circumstances:
               <ul className='list-disc pl-5' >
                    <li>
                        With service providers: We may share your information with third-party service providers
                        who help us deliver our services.
                    </li>
                    <li>
                        With affiliates: We may share your information with our affiliates for business purposes.
                    </li>
                    <li>
                        With legal authorities: We may share your information when required by law or to protect
                        our rights.
                    </li>
                    <li>
                        With your consent: We may share your information if you have given us permission to do so.
                    </li>
                </ul>
                <br />

                <b>4. YOUR PRIVACY CHOICES</b><br />
                <ul className='list-disc pl-5'>
                    <li>
                        Access and Correction: You can access and update your personal information
                        through your account settings.
                    </li>
                    <li>
                        Marketing Preferences: You can opt out of receiving promotional communications
                        by clicking the "unsubscribe" link in our emails or contacting us directly.
                    </li>
                    <li>
                         Cookies: Manage your cookie preferences through your browser settings.
                    </li>
                </ul><br />

                <b>5. DATA SECURITY</b><br />
                We implement appropriate technical and organizational measures to protect your
                information from unauthorized access, disclosure, or destruction. However, no system can
                guarantee complete security, so please use our services with caution.<br /><br />

                <b>6. THIRD-PARTY LINKS</b><br />
                Our Website may contain links to third-party websites. We are not responsible for the
                privacy practices of these websites and encourage you to review their policies before
                providing any personal information.<br /><br />

                <b>7.  CHILDREN’S PRIVACY</b><br />
                Our Website is not intended for children under the age of 13, and we do not knowingly
                collect information from children. If you believe we have collected information from a child,
                please contact us immediately. <br /><br />

                <b>8. CHANGES TO THIS PRIVACY POLICY</b><br />
                We may update this Privacy Policy from time to time to reflect changes in our practices or
                for other operational, legal, or regulatory reasons. The updated policy will be posted on our
                Website with the "Effective Date" at the top.

                <br /><br />
                <b>9. CONTACT US</b><br />

                If you have any questions or concerns about this Privacy Policy or our practices, please
                contact us at:<br />
                Gasleet Support
                Email: info@zulfahgroup.com<br />
                Phone: +2348093568767 <br />
                Address: 7 Aduke Close Off Maryland Crescent, Maryland.<br /><br />

                Thank you for trusting Gasleet to manage your gas delivery and tracking needs. <hr />

                </p>


            </div>
           
            </div>
        </div>
        {/* </ThreeDashboardBars> */}

        </>
    );
}

export default Index;