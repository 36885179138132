import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import TextInput from '../../../components/shared/TextInput';
import Button from '../../../components/shared/Button';
import blackCy from '../../../assets/images/gasContainerBlack.svg';
import Logo from '../../../assets/images/logo.svg';
import SideGasCylinder from '../../../components/shared/SideGasCylinder';
import logoLight from '../../../assets/images/logoWhite.svg';
import correctMarkIcon from '../../../assets/images/correctMark.svg';
const Index = () => {
  const initialValues = {
    password: '',
    comfirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().label('password').required(),
    retypePassword: Yup.string().label('confirm password').required(),
  });

  const handleLogin = async (values) => {
    console.log(values);
  };

  return (
    <>
      <SideGasCylinder />
      <div className="bg-[#EAF3F0] pb-6 max-[100%]">
        <section className="px-[7rem] pt-[65px] flex gap-12">
          <div className="w-1/2 py-80 flex flex-col gap-8">
            <img src={Logo} className="w-72" alt="Gasleet Logo" />
          </div>
          <div className="w-1/2 relative ">
            <div className="flex flex-col w-full p-3 md:p-0 md:w-2/3 lg:w-[500px] mt-10 md:mt-20 mb-16 md:mb-10">
              <div className="p-6 md:p-10 bg-white rounded-[15px]">
                <img src={correctMarkIcon} alt="Message " />
                <h1 className="text-[#C25D41] text-3xl font-FjallaOne font-semibold">
                  Set your Password
                </h1>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleLogin}
                >
                  {({ handleSubmit, isValid }) => {
                    return (
                      <Form className="w-full mt-8" onSubmit={handleSubmit}>
                        <div className="flex flex-col">
                          <label htmlFor="email" className="text-sm text-black">
                            Password
                          </label>
                          <Field
                            component={TextInput}
                            type="password"
                            name="password"
                            id="password"
                            placeholder="********"
                          />
                        </div>
                        <div>
                          <p className="text-xs">
                            {' '}
                            Password should contain upper case, Special
                            character , and number minimum character(8){' '}
                          </p>
                          <br />
                          <p> Password Strength</p>
                          😎😬😉
                          <br />
                          <br />
                        </div>

                        <div className="flex flex-col mt-5">
                          <label
                            htmlFor="password"
                            className="text-sm text-black"
                          >
                            Re-type Password
                          </label>
                          <Field
                            component={TextInput}
                            type="password"
                            name="retypePassword"
                            id="retypePassword"
                            placeholder="********"
                          />
                        </div>

                        <div className="flex flex-row mt-5 justify-end">
                          <span className="text-black text-xs font-light"></span>
                        </div>

                        <div className="flex flex-row mt-5">
                          <Button
                            type="submit"
                            title="Create Account"
                            variant="primary"
                            disabled={!isValid}
                          />
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Index;