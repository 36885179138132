import React from 'react';
import classNames from 'classnames';
import ButtonSpinnerSvg from './ButtonSpinnerSvg';

const Button = ({ title, size = 'md', variant = 'primary', isLoading=false, ...props }) => (
  <button
    className={classNames(
      'inline-flex items-center justify-center w-auto text-[#fff] font-medium rounded-md shadow focus:outline-none disabled:opacity-50 transition ease-in-out duration-300',
      {
        'py-3 px-10 text-sm font-normal': size === 'sm',
        'py-3 px-10 text-sm font-normal w-full': size === 'md',
        'bg-[#5BCCF7] hover:bg-[#5BCCF7]': variant === 'primary',
      },
    )}
    {...props}
  >
    {isLoading ? 'Loading' : title}
  </button>
);

export default Button;
