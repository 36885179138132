import React from 'react';
import RightNav from './DashboardBars/components/RightNav';
import SideNav from './DashboardBars/components/SideNav';
import Header from './DashboardBars/components/Header';

const ThreeDashboardBars = ({ children }) => {
  return (
    <div className="flex bg-[#EAF3F0]">
      <div className="lg:w-[15%] w-[20%]">
        <SideNav />
      </div>

      <div className="lg:w-[80.5%] w-[80%]">
        <Header />
        {children}
      </div>
      {/* <div className="lg:w-[14.5%] xs:hidden sm:hidden md:hidden">
        <RightNav />
      </div> */}
    </div>
  );
};

export default ThreeDashboardBars;
