import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import TextInput from '../../../components/shared/TextInput';
import Button from '../../../components/shared/Button';
import blackCy from '../../../assets/images/gasContainerBlack.svg';
import Logo from '../../../assets/images/logo.svg';
import  SideGasCylinder from '../../../components/shared/SideGasCylinder';
import logoLight from '../../../assets/images/logoWhite.svg';
import correctMark from '../../../assets/images/correctMark.svg';

const Index = () => {
  const initialValues = {
    password: '',
    comfirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().label('password').email().required(),
    comfirmPassword: Yup.string().label('comfirmPassword').required(),
  });

  const handleLogin = async (values) => {
    console.log(values);
  };

  return (
    <>
    <SideGasCylinder />
    <div className="bg-[#EAF3F0] pb-6 max-[100%]">
      
      <section className="px-[7rem] pt-[65px] flex gap-12">
        <div className="w-1/2 py-48 flex flex-col gap-8">
           <img src={Logo} className='w-72' alt='Gasleet Logo' />
        </div>
        <div className="w-1/2 relative ">
           <div className="flex flex-col w-full p-3 md:p-0 md:w-2/3 lg:w-[500px] mt-10 md:mt-20 mb-16 md:mb-10">
              <div className="p-6 md:p-10 bg-white rounded-[15px]">
              <img src={correctMark} className='w-25' alt='Password updated' />
              <h1 className="text-[#C25D41] text-3xl font-FjallaOne font-semibold">
                Password <br /> Updated
              </h1><br />
                <h4 className="text-black text-sm font-light">Your password has been updated successfully,
                  you can now Login with your new password {""}
                 
                </h4><br />
                <Link to='/login'>
                <div className="flex flex-row mt-5">
                        <Button
                          type="button"
                          title="Login"
                          variant="primary"
                        />
                  </div>
                  <br /><br />

                </Link>
              
               
              

            
            </div>
          </div>
        </div>
      </section>

    </div>
   
    </>
  );
};

export default Index;