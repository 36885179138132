import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/shared/Button';
import LightButton from '../../components/shared/LightButton';
import bami from '../../assets/images/bamidele.svg';
import ThreeDashboardBars from '../../components/shared/ThreeDashboardBars';

const Index = () => {
  const [activeButton, setActiveButton] = useState(1);
  //The useState() hook is used to track each active button, the first button will automatically appear active unless another button is clicked, if the useState() paramenter has been set to '0', buttons will only be active when one of the is clicked.
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    //this function is used to toggle and display the active button content when the button is clicked, it tracks all button with the buttonId parameter it receives.
  };
  return (
    <ThreeDashboardBars>
      <div className="bg-[#EAF3F0]">
        <div className="px-[rem]">
          <div className="bg-[#5BCCF7] flex justify-center lg:py-9 md:py-7 sm:py-4 xs:py-2">
            <div className="flex flex-col items-center gap-2">
              <div className="sm:h-[4.5rem] sm:w-[4.5rem] xs:h-[4.5rem] xs:w-[4.5rem]">
                <img src={bami} alt="" />
              </div>
              <h2 className="font-FjallaOne lg:text-4xl md:text-3xl text-2xl text-[#352B26]">
                Coker Oluwafemi
              </h2>
            </div>
          </div>
          <div className="flex gap-5 sm:gap-3 xs:gap-2 justify-center pt-10 pb-10">
            <button
              className={
                activeButton === 1
                  ? 'border-b-4 sm:border-b-2 xs:border-b-2 border-[#C25D41] text-xl font-Lora'
                  : 'text-xl font-Lora'
              }
              onClick={() => handleButtonClick(1)}
            >
              Basic Info
            </button>
            <button
              className={
                activeButton === 2
                  ? 'border-b-4 sm:border-b-2 xs:border-b-2 border-[#C25D41] text-xl font-Lora'
                  : 'text-xl font-Lora'
              }
              onClick={() => handleButtonClick(2)}
            >
              Contact
            </button>
            <button
              className={
                activeButton === 3
                  ? 'border-b-4 sm:border-b-2 xs:border-b-2 border-[#C25D41] text-xl font-Lora'
                  : 'text-xl font-Lora'
              }
              onClick={() => handleButtonClick(3)}
            >
              Gas Profile
            </button>
          </div>
          <div className="flex justify-center">
            {/* Render div based on activeButton */}
            {activeButton === 1 && (
              <form>
                <div className="flex flex-col gap-7 p-4">
                  <div className="flex gap-8 p-2 sm:flex-col xs:flex-col sm:gap-4 xs:gap-3">
                    <div className="flex flex-col gap-2">
                      <label className="font-Lora text-sm">First Name</label>
                      <input
                        type="text"
                        placeholder="John"
                        className="py-2 px-3 bg-white w-[15rem] rounded-md outline-none"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="font-Lora text-sm">Last Name</label>
                      <input
                        type="text"
                        placeholder="Doe"
                        className="py-2 px-3 bg-white w-[15rem] rounded-md outline-none"
                      />
                    </div>
                  </div>

                  <div className="flex gap-8 p-2 sm:flex-col xs:flex-col sm:gap-4 xs:gap-3">
                    <div className="flex flex-col gap-2">
                      <label className="font-Lora text-sm">Gender</label>
                      <select className="py-2 px-3 w-[15rem] bg-white rounded-md outline-none">
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="font-Lora text-sm">Date of Birth</label>
                      <input
                        type="date"
                        className="py-2 px-3 bg-white w-[15rem] rounded-md outline-none"
                      />
                    </div>
                  </div>

                  <Link className="flex justify-end">
                    <Button title="Next" variant="primary" />
                  </Link>
                </div>
              </form>
            )}
            {activeButton === 2 && (
              <form>
                <div className="flex flex-col lg:gap-7 sm:gap-4 xs:gap-3 p-4">
                  <div className="flex gap-8 p-2 sm:flex-col xs:flex-col sm:gap-4 xs:gap-3">
                    <div className="flex flex-col gap-2">
                      <label className="font-Lora text-sm">Phone Number</label>
                      <input
                        type="text"
                        placeholder="234 817 9904 367"
                        className="py-2 px-3 bg-white w-[15rem] rounded-md outline-none"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="font-Lora text-sm">Email</label>
                      <input
                        type="email"
                        placeholder="myexample@gmail.com"
                        className="py-2 px-3 bg-white w-[15rem] rounded-md outline-none"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col p-2 gap-2">
                    <label className="font-Lora text-sm">
                      Delivery Address
                    </label>
                    <textarea
                      rows="4"
                      placeholder="1, aduke close, Maryland, Ikeja"
                      className="py-2 px-3 resize-none bg-white rounded-md outline-none"
                    />
                  </div>

                  <Link className="flex justify-end">
                    <Button title="Next" variant="primary" />
                  </Link>
                </div>
              </form>
            )}
            {activeButton === 3 && (
              <form>
                <div className="flex flex-col gap-5 p-4">
                  <div className="flex gap-8 p-2 sm:flex-col xs:flex-col sm:gap-4 xs:gap-3">
                    <div className="flex flex-col w-full gap-2">
                      <label className="font-Lora text-sm">
                        Container Size
                      </label>
                      <select className="py-2 px-3 bg-white w-[15rem] rounded-md outline-none">
                        <option value="3kg">3kg</option>
                        <option value="5kg">5kg</option>
                        <option value="6kg">6kg</option>
                        <option value="12kg">12kg</option>
                        <option value="25kg">25kg</option>
                      </select>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="font-Lora text-sm">
                        Amount of Gas Container
                      </label>
                      <input
                        type="text"
                        placeholder="1"
                        className="py-2 px-3 w-[15rem] bg-white rounded-md outline-none"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end gap-3">
                    <Link>
                      <LightButton
                        title="Add Gas"
                        variant="primary"
                        style="border-2 border-[#C25D41] px-6 bg-white"
                      />
                    </Link>
                    <Link>
                      <Button title="Done" variant="primary" />
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </ThreeDashboardBars>
  );
};

export default Index;
