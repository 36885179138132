import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import TextInput from '../../components/shared/TextInput';
import Button from '../../components/shared/Button';
import blackCy from '../../assets/images/gasContainerBlack.svg';
import Logo from '../../assets/images/logo.svg';
import  SideGasCylinder from '../../components/shared/SideGasCylinder';
import logoLight from '../../assets/images/logoWhite.svg';


const Index = () => {
  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup
      .string()
      .label("First name")
      .test("alphabets", "Name must only contain alphabets", (value) => {
        return /^[A-Za-z]+$/.test(value);
      }).required(),
    lastName: Yup
      .string()
      .label("First name")
      .test("alphabets", "Name must only contain alphabets", (value) => {
        return /^[A-Za-z]+$/.test(value);
      }).required(),
    email: Yup.string().label("Email").email().required(),
    phoneNumber: Yup.string().label("Phone number").length(11).required(),
    password: Yup.string().label("Password").min(8).required(),
    referralCode: Yup.string().label("Referral code"),
    agreement: Yup
      .boolean()
      .oneOf([true], "Accept Terms & Conditions is required")
      .label("Terms & conditions")
      .required(),
  });

  
  const handleSignup = async (e) => {
    e.preventDefault();
  
    console.log(initialValues);

  };

  return (
    <>
    <span className='sm:hidden'>
    <SideGasCylinder />
    </span>
  
    {/* <div className=" pb-6 max-[100%]"> */}
      
      <section className="bg-[#EAF3F0] h-screen w-full setup-screens flex-col justify-center items-center px-[7rem]  flex gap-12">
        <div className="flex">
                  {/* <img src={Logo} className='w-72' alt='Gasleet Logo' /> */}
        
           <div className="flex flex-col p-3 lg:w-[600px] md:w-[400px] sm:w-[400px] md:p-0 mt-10 md:mt-20 mb-16 md:mb-10">
            <div className="p-6 md:p-10 bg-white rounded-[15px]">
              <h1 className="text-[#C25D41] text-center text-3xl font-FjallaOne font-semibold">
               Get Started
              </h1>
            <p className="text-center leading-normal">
          We'll get you set up in less than one minute
        </p>
              

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                  }, 400);
                }}
              >
                 {({ handleSubmit, isValid }) => {
                  return (
                    <Form className="w-full mt-8" onSubmit={handleSubmit}>
                     <div className="flex flex-col">
                        <label
                        htmlFor="firstname"
                        className="text-sm text-black"
                        >
                        FirstName
                        </label>
                        <Field
                        component={TextInput}
                        type="text"
                        name="firstname"
                        id="firstname"
                        placeholder="lekan"
                        onChange={(e) => initialValues.firstName = e.target.value}
                        />
                     </div>
                     <div className="flex flex-col">
                        <label
                        htmlFor="lastname"
                        className="text-sm text-black"
                        >
                        LastName
                        </label>
                        <Field
                        component={TextInput}
                        type="text"
                        name="lastname"
                        id="lastname"
                        placeholder="olawale"
                        />
                     </div>
                      <div className="flex flex-col">
                        <label
                          htmlFor="email"
                          className="text-sm text-black"
                        >
                          Email Address
                        </label>
                        <Field
                          component={TextInput}
                          type="email"
                          name="email"
                          id="email"
                          placeholder="user@email.com"
                          onChange={(e) => initialValues.email = e.target.value}
                        />
                      </div>

                      <div className="flex flex-col mt-5">
                        <label
                          htmlFor="phoneNumber"
                          className="text-sm text-black"
                        >
                          PhoneNumber
                        </label>
                        <Field
                          component={TextInput}
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          placeholder="phoneNumber"
                          onChange={(e) => initialValues.phoneNumber = e.target.value}
                        />
                      </div>
                      <div className="flex flex-col mt-5">
                        <label
                          htmlFor="password"
                          className="text-sm text-black"
                        >
                          Password
                        </label>
                        <Field
                          component={TextInput}
                          type="text"
                          name="password"
                          id="password"
                          placeholder="password"
                         
                        />
                      </div>
                      <div className="flex flex-col mt-5">
                        <label
                          htmlFor="password"
                          className="text-sm text-black"
                        >
                          Referral Code
                        </label>
                        <Field
                          component={TextInput}
                          type="text"
                          name="referralCode"
                          id="referralCode"
                          placeholder="Referral Code"
                         
                        />
                      </div>

                      <div className="flex flex-row mt-5 justify-end">
                        <span className="text-black text-xs font-light">
                         
                          <Link
                            to="/auth/sign-up"
                            className="text-[#000] hover:text-[#000] transition ease-in-out duration-300"
                          >
                             Forgot Password ?{' '}
                          </Link>
                        </span>
                      </div>

                      <div className="flex flex-row mt-5">
                        <Button
                          type="submit"
                          title="Create Account"
                          variant="primary"
                          disabled={isValid}
                        />
                      </div>
                     
                     
                    </Form>
                  );
                }}
              </Formik>

            
            </div>
          </div>
         
        </div>
      </section>

    {/* </div> */}
   
    </>
  );
};

export default Index;