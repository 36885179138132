import React from 'react';
import SideGasCylinder from '../../components/shared/SideGasCylinder';
import Navbar from '../../components/shared/Navbar';
import ashCy from '../../assets/images/gasContainerAsh.svg';
import stones from '../../assets/images/blackStone.svg';
import Button from '../../components/shared/Button';

const index = () => {
  return (
    <div className="relative bg-[#EAF3F0]">
      <SideGasCylinder props="md:hidden sm:hidden xs:hidden" />
      <Navbar />
      <div className="px-28 py-12 md:px-5 sm:py-20 md:py-20 sm:px-2 xs:px-1">
        <div className="lg:bg-[#dfa08f] md:bg-[#dfa08f] rounded lg:flex md:flex lg:gap-4 md:gap-2 flex-1">
          <div className="py-6 px-5 md:px-3 sm:px-2 xs:px-1 w-1/2 sm:w-full xs:w-full">
            <h1 className="lg:text-5xl md:text-4xl text-2xl font-FjallaOne sm:text-center xs:text-center pt-1 lg:pb-8 md:pb-7 sm:pb-3 xs:pb-2 text-[#C25D41]">
              Pay with card!
            </h1>
            <p className="font-Lora lg:text-sm text-xs sm:text-center xs:text-center opacity-60">
              Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl
              sed molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
              consectetur. Tellus
            </p>
            <form>
              <div className="pt-16 md:pt-11 sm:pt-6 xs:pt-5">
                <div className="flex flex-col">
                  <label className="font-Lora text-sm">Card Number</label>
                  <input
                    placeholder="16digits number in front of your card"
                    type="text"
                    className="py-3 px-4 bg-white outline-none rounded-md"
                  />
                </div>
                <div className="grid grid-cols-2 gap-x-5 py-6 ">
                  <div className="flex flex-col">
                    <label className="font-Lora text-sm">
                      Card Expiry Date
                    </label>
                    <input
                      placeholder="08/24"
                      type="text"
                      className="py-3 px-4 bg-white outline-none rounded-md"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="font-Lora text-sm">Card CVV</label>
                    <input
                      placeholder="866"
                      type="text"
                      className="py-3 px-4 bg-white outline-none rounded-md"
                    />
                    <p>
                      <small className="lg:ml-[4.5rem] md:text-[9px] sm:text-[9px] sm:ml-7 xs:text-[9px] xs:ml-4">
                        *Check the 3-digits behind your card
                      </small>
                    </p>
                  </div>
                </div>
                <div className="flex gap-2 items-center pb-5">
                  <input
                    type="checkbox"
                    className="py-3 px-4 bg-white outline-none h-6 w-5 rounded-md"
                  />
                  <p>Remember this card</p>
                </div>
              </div>
              <div className="text-center">
                <Button title="Pay" variant="primary" size="md" />
              </div>
            </form>
          </div>
          <div className="lg:bg-white md:bg-white rounded w-1/2 flex justify-center lg:p-10 md:p-2 sm:hidden xs:hidden">
            <div className="relative md:pt-4">
              <div className=" bg-[#dfa08f] md:h-[23rem] rounded-full">
                <img
                  src={ashCy}
                  alt="Light blue gas container showing Gasleet logo with a white knob."
                  className="relative bottom-1 md:bottom-0 md:right-4 right-2 md:w-[20rem]"
                />
              </div>
              <img
                src={stones}
                alt="Three black stones placed in a triangular shape."
                className="absolute bottom-12 md:bottom-24 left-6"
              />
              <p className="font-Lora text-sm pt-8 md:pt-24 lg:w-[31rem] opacity-60 lg:text-center">
                With GasLeetApp, your loyalty pays off. Get rewarded for smart
                gas consumption. Earn loyalty points with each subscription and
                use them for discounts on future deliveries.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
