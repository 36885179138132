import React from 'react';
import whatsappLogo from '../../../../assets/images/whatsappIcon.svg';
import instagramLogo from '../../../../assets/images/instagramIcon.svg';
import linkedInLogo from '../../../../assets/images/linkedInIcon.svg';
import x from '../../../../assets/images/X.svg';
import box from '../../../../assets/images/giftBox.svg';

const index = () => {
  return (
    <div>
      <div className="flex gap-12 justify-center items-center pb-2 border-b-2">
        <div className="flex flex-col items-center">
          <img src={whatsappLogo} alt="Green Whatsapp Icon" />
          <p>Whatsapp</p>
        </div>
        <div className="flex flex-col items-center">
          <img src={instagramLogo} alt="Instagram Icon" />
          <p>Instagram</p>
        </div>
        <div className="flex flex-col items-center">
          <img src={linkedInLogo} alt="LinkedIn Icon" />
          <p>LinkedIn</p>
        </div>
        <div className="flex flex-col items-center">
          <img src={x} alt="X Icon formerlly known as Twitter" />
          <p>X</p>
        </div>
      </div>
      <div className="px-10 py-2 flex flex-col items-center gap-7">
        <p className="text-[#C25D41] w-[19.2rem] text-4xl font-FjallaOne text-center">
          Refer your friends and earn
        </p>
        <img src={box} alt="A gift box" />
        <p className="text-[#00000099] w-[17rem] text-center">
          Lorem ipsum dolor sit amet consectetur. Volutpat id eu
        </p>
      </div>
    </div>
  );
};

export default index;
