import React from 'react';
import { showAccordion } from '../../utils';
import Accordion from '../ProductPage/components/Accordion';
import DashboardBars from '../../components/shared/DashboardBars';

const index = () => {
  return (
    <DashboardBars>
      <div className="px-6">
        <h1 className="font-FjallaOne text-5xl py-10 text-[#C25D41]">FAQs</h1>
        <p className="text-[#352B26] w-[40rem] font-Lora text-lg">
          Lorem ipsum dolor sit amet consectetur. Nibh aliquam pulvinar nisl sed
          molestie arcu laoreet aliquam. Habitasse hendrerit massa magna
          consectetur. Tellus
        </p>
        <div className="pt-12">
          {showAccordion.map((item, i) => {
            return (
              <div key={i} className="">
                <Accordion title={item.title} content={item.content} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="fixed bottom-0 right-9">
        <h3 className="text-[#352B26] font-FjallaOne bg-white py-5 rounded-t-3xl text-3xl pl-8 pr-[38rem]">
          Messages
        </h3>
      </div>
    </DashboardBars>
  );
};

export default index;
