/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import axios from '../api';
import {loginURL, registerURL} from '../apiUrl';
// import axios from 'axios'

export async function login (email, password) {

 const { data, error } =  await axios.post(loginURL,{ email, password }, 
                  { headers: { 
                     'Content-Type': 'application/x-www-form-urlencoded'
                  }
})
if(error) throw new Error(error.message)
return data

};

export async function register(body) {
 
  const { data, error } =  await axios.post(registerURL,{ body }, 
    { headers: { 
       'Content-Type': 'application/x-www-form-urlencoded'
    }
})
if(error) throw new Error(error.message)
return data

};

export const verifyOtp = async({email, otp}) => {
  return await axios.get(`/otp/verify/${email}/${otp}`);
};

export const resendOtp = async(data) => {
  return await axios.post('/otp/resend', data);
};

export const startForgetPassword = async({email}) => {
  return await axios.post(`/customer/forget-password/start/${email}`);
};

export const completeForgetPassword = async({email}) => {
  return await axios.post(`/customer/forget-password/start/${email}`);
};

export const customerProfile = async() => {
  return await axios.get(`/customer/profile`);
};

export const customerConsumptionStatistics = async() => {
  return await axios.get(`/customer/consmption-statistics`);
};

export const customerOrders = async() => {
  return await axios.get(`/customer/orders`);
};

export const customerStartOrder = async(data) => {
  return await axios.post(`/customer/order/start`, data);
};

export const customerCompleteOrder = async(data) => {
  return await axios.post(`/customer/order/complete`, data);
};